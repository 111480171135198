import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fade, InputLabel, LinearProgress, MenuItem, Paper, Popper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import CreateTeam from './CreateTeam';
import { team } from './Request';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { teamDeletor } from './Request';
import Consent from '../Utilities/Consent';
import DeleteAlert from '../Utilities/Alert';
import Message from '../Utilities/Message';
import { ToastContainer, toast } from 'react-toastify';
const Teams = () => {
    const worksapceInfo = useSelector(state => state.workspace.workspaceDetails)
    const userInfo = useSelector(state => state.userInfo.user)
    const workspaceId = worksapceInfo.length > 0 && worksapceInfo[0].id
    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        teamName: '',
        members: '',
        role: '',
        email: '',
        w_id: 0,
        t_id: 0,
        forward: false

    });
    const [open, setOpen] = useState(false)
    const [openPoper, setOpenPopeer] = useState(false)
    const navigate = useNavigate()

    const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST
    const access_token = localStorage.getItem('access_token')
    const [anchorEl, setAnchorEl] = React.useState(null);

    
    

    const handlePoper = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenPopeer((previousOpen) => !previousOpen);
        
        

    };
    const handleClose = (event) => {
        setAnchorEl(null);
        setOpenPopeer((previousOpen) => !previousOpen);
        
      };

    const canBeOpen = openPoper && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;


    const fetchTeams = async () => {
        setLoading(true)
        await axios.get(`${dashboard_backend}/team/workspaces/${workspaceId}/team/`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        }, { withCredentials: true }).then((res) => {
            if (res.status === 200) {
                setTeams(res.data)
                setLoading(false)

            }


        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        if (worksapceInfo.length > 0) {
            fetchTeams()
        }
    }, [])

    const handleCreateTeam = () => {
        if (worksapceInfo.length > 0) {
            setFormData({ ...formData, w_id: worksapceInfo[0].id })
            team({ team_name: formData.teamName }, workspaceId).then((res) => {
                fetchTeams()
                setOpen(false)

            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const handleTeamDelete = (w_id, t_id) => {
        try{
            teamDeletor(w_id, t_id).then((res) => {
                if(res.status === 200 || res.status === 204){
                    toast.success("Team Deleted")
                    fetchTeams()

                }
            }).catch((err) => {
                console.log(err)
            })
        }catch(error){
            console.log(error)
        }
    }


    return (
        <Box>
            <ToastContainer />
            {loading && <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>}
            <Consent/>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add a New Team in your Organization"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ width: '100%', mt: 2 }}>
                        <Typography variant="body1" gutterBottom>
                            Name your new Team
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="teamName"
                            placeholder="Enter Team name"
                            value={formData.teamName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    teamName: e.target.value,
                                    forward: !!e.target.value,
                                })
                            }
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateTeam} >Submit</Button>
                    <Button onClick={() => setOpen(false)} autoFocus>
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
            {!loading && <>
            { <Container fullWidth sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>{
                worksapceInfo.length > 0 && <>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <h6 style={{justifySelf:"start"}}>{worksapceInfo[0].name}</h6>
                        {teams.length > 0 && <p>{teams.length} Team in this Organization</p>}
                    </div>
                    {teams.length > 0 && <Button onClick={() => setOpen(true)}><AddIcon />Add New Team</Button>}
                </>
            }
            </Container>}
            {teams.length > 0 ?

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead >
                            <TableRow>

                                <TableCell align="left">Team Name</TableCell>
                                <TableCell align="left">Members</TableCell>
                                <TableCell align="left">manager</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams.length > 0 && teams.map((team, index) => (
                                <TableRow
                                    key={team.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    // onClick={() => navigate(`/organization/team/${workspaceId}/${team.id}`)}
                                >


                                    <TableCell align="left" sx={{":hover":{cursor:"pointer"}}} onClick={()=>  navigate(`/organization/team/${workspaceId}/${team.id}`)}>{team.team_name}</TableCell>

                                    <TableCell align="left">{team.members_count}</TableCell>
                                    <TableCell align="left">{team.workspace_created_by}</TableCell>
                                    <TableCell align="left"><Box sx={{display:"flex",gap:"10px"}}>
                                    <VisibilityIcon onClick={() => navigate(`/organization/team/${team.workspace_id}/${team.id}`)} sx={{":hover":{cursor:"pointer"}}}/>
                                        <DeleteAlert deleting={`Team`} name={team.team_name} controlDelete={()=>handleTeamDelete(team.workspace_id,team.id)}> <DeleteIcon sx={{":hover":{cursor:"pointer"}}}/></DeleteAlert>
                                        </Box></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                : <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '70%', justifyContent: 'center', alignItems: 'center' }}>
                    <CreateTeam formData={formData} setFormData={setFormData} />
                    <Button type='submit' onClick={handleCreateTeam}>Add</Button>
                </Box>}
            </>}
            


        </Box>
    )
}

export default Teams