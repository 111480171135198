import React from 'react';
import { Box, Typography, InputLabel, Select, MenuItem, TextField } from '@mui/material';


const InvitePeople = ({ formData, setFormData }) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        textAlign: 'start',
        gap: 2,
        marginTop: 8,
      }}
    >
      
      <Typography variant="h4" component="h2" gutterBottom>
        Invite people to join your Organization
      </Typography>
      <Typography variant="body1" gutterBottom>
        Invited members will need to accept the Workspace invitation before joining.
      </Typography>
      
      <Box component="form" sx={{ width: '100%', mt: 2 }}>
        <InputLabel htmlFor="email-input" sx={{ mb: 1 }}>Add emails</InputLabel>
        <TextField
          fullWidth
          id="email-input"
          type="email"
          placeholder="Enter Email"
          variant="outlined"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value, forward: e.target.value && 2 })}
          size="small"
        />
        
        <InputLabel id="role-select-label" sx={{ mt: 2 }}>Role</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value, forward: e.target.value && true })}
          size="small"
          fullWidth
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="member">Member</MenuItem>
        </Select>
      </Box>
    </Box>
  );
};

export default InvitePeople;
