import React, { useState, useEffect, createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  let tokenClient = null;

  // Initialize Google API client and token client
  useEffect(() => {
    const initializeGapi = async () => {
      await window.gapi.load("client:auth2");
      await window.gapi.client.init({
        clientId: clientId,
        scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile",
      });

      // Initialize token client
      tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile",
        callback: (tokenResponse) => {
          setAccessToken(tokenResponse.access_token);
          scheduleTokenRefresh();
        },
      });
    };
    initializeGapi();
  }, [clientId]);

  // Function to log in the user and get an access token
  const handleLogin = () => {
    if (tokenClient) {
      tokenClient.requestAccessToken();
    }
  };

  // Refresh the token automatically before it expires
  const scheduleTokenRefresh = () => {
    const expirationTime = 3600 * 1000; // 1 hour in milliseconds
    setTimeout(() => {
      if (tokenClient) {
        tokenClient.requestAccessToken({ prompt: "none" });
      }
    }, expirationTime - 60000); // Refresh 1 minute before expiry
  };

  return (
    <AuthContext.Provider value={{ accessToken, handleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access auth context
export const useAuth = () => useContext(AuthContext);



export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID, // Replace with your client ID
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: 'https://dashboard.liznr.com/sign-in', // Replace with your redirect URI
    }
};
