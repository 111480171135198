import React, { useEffect, useState } from 'react'
import './workspace.css'

// axios
import axios from 'axios'
import DenseTable from './WorkspaceTable'
import CreateWorkspace from './CreateWorkspace'
import CreateTeam from './CreateTeam'
import InvitePeople from './InvitePeople'
import { Box, Button, LinearProgress } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'

import { workspace, team, member } from './Request'
import { useDispatch, useSelector } from 'react-redux'
import Teams from './Teams'
import ManageWorkspace from './ManageWorkspace'

import { setWorkspace, setWorkspaceInfo } from '../../Store/Slices/WorkspaceSlice'



const Worksapces = () => {

  let access_token ;
  const [input, setInput] = React.useState({})
  const [isWorkSpace, setIsWorkSpace] = React.useState(false)
  const [worksapceInfo, setWorkSpaceInfo] = React.useState({})
  const [currentStep, setCurrentStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    name: '',
    teamName: '',
    members: '',
    role: 'member',
    email: '',
    w_id: 0,
    t_id: 0,
    forward:false

  });
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  
 

  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST
  const backend = process.env.REACT_APP_BACKEND_HOST

  useEffect(() => {
    access_token = localStorage.getItem('access_token')
  }, [])


  React.useEffect(() => {
    setLoading(true)
    axios.get(`${dashboard_backend}/team/workspaces/`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }).then((res) => {
      
      if (res.data.length > 0) {
        setWorkSpaceInfo(res)
        setIsWorkSpace(true)
        dispatch(setWorkspace(true))
        dispatch(setWorkspaceInfo(res.data))

        
        

      }
      setLoading(false)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })

  }, [])






  const createWorkspace = async () => {
    try {
      
      const response = await axios.post(`${dashboard_backend}/team/workspaces/`, input, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      if (response.status === 201) {
        
        setInput({})
        setIsWorkSpace(true)
        
      }

    } catch (error) {
      
      console.log(error)
    }
  }

  const handelSubmit = (e) => {
    e.preventDefault()
    const roomname = e.target.roomname.value
    const description = e.target.description.value
    setInput({
      ...input,
      'name': roomname,
      "description": description
    })
    createWorkspace()
  }
  const steps = [
    <CreateWorkspace formData={formData} setFormData={setFormData} />,
    <CreateTeam formData={formData} setFormData={setFormData} />,
    <InvitePeople formData={formData} setFormData={setFormData} />
  ]

  // API Request
  const handleAPI = async (step, data, w_id = null, t_id = null) => {
    let response = null
    try {

      switch (step) {
        case 0:
          response = await workspace({ 'name': data.name })
          setFormData({ ...formData, w_id: response.data.id })
          break;
        case 1:
          response = await team({ 'team_name': data.teamName }, formData.w_id)
          setFormData({ ...formData, t_id: response.data.id })
          break;
        case 2:
          await member(data, w_id, t_id)
          break;
      }
    } catch (error) {
      console.log(error)
    }
  }


  const handleNext = () => {
    if(formData.forward==false){
      // console.log(formData);
      
      return
    }
    if (currentStep < steps.length -1 ) {
      setCurrentStep(currentStep + 1);
      if (currentStep !== 2) {
        handleAPI(currentStep, formData)
        // console.log(currentStep,formData);
      }
      else {
        handleAPI(currentStep, formData, formData.w_id, formData.t_id)
      }

    } else {
      
      // alert('Form submitted!');
      handleAPI(currentStep, formData, formData.w_id, formData.t_id)
      navigate('/')
    }
  };

  const handleBack = () => {
    if (currentStep > 0 && currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    else {
      // alert('Are you sure')
      if (currentStep != 0) {
        // window.location.reload()
        navigate('/')
      } else {
        navigate('/')
      }

    }
  };


  return (
    <div className='workspace-container'>

      {!loading &&  !isWorkSpace ? <>
          <div className="create-workspace">
            {steps[currentStep]}
            <Box
              sx={{
                paddingX: 10,
                alignSelf: 'start',

              }}
            >

              <Button variant="contained" onClick={handleNext}
                sx={{ marginRight: 1 }}
              >
                {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
              </Button>
              <Button
                // variant="contained"
                onClick={handleBack}
                sx={{ marginRight: 1 }}

              >
                {currentStep === 0 ? 'Cancel' : 'Skip for now'}
              </Button>
            </Box>

          </div>


        </> : !loading && <>
          <div className="workspace-list">
            {/* <h2>Organization</h2> */}
            {
              // <DenseTable data={worksapceInfo} />
              
              <Outlet/>
              
            }
          </div>

        </> } {loading && <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>}
    </div>
  )
}

export default Worksapces