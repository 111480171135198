import React, { useEffect, useState } from 'react';

const GoogleCalendar = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  // Step 1: Load the Google Identity Services (GIS) and initialize the token client
  useEffect(() => {
    const initializeGoogleClient = () => {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: '530303062762-2pnen77012a0tadp0ekpv61a9pdf0bce.apps.googleusercontent.com', // Replace with your client ID
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        callback: handleAuthResponse,
      });
      setTokenClient(client);
    };

    if (window.google && window.google.accounts && window.google.accounts.oauth2) {
      initializeGoogleClient();
    }
  }, []);

  // Step 2: Initialize the Google API client library for Calendar API
  useEffect(() => {
    const loadGapiClient = () => {
      window.gapi.load('client', async () => {
        await window.gapi.client.init({
          apiKey: 'AIzaSyB0VzT7Oik5NvSl7Kq9T0UXq9eiDs33A0g', // Replace with your API key
          discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
        });
        console.log('Google API client initialized');
      });
    };

    if (window.gapi) {
      loadGapiClient();
    }
  }, []);

  // Step 3: Handle the authentication response
  const handleAuthResponse = async (response) => {
    if (response.error) {
      console.error('Authentication failed:', response.error);
      return;
    }
    setIsSignedIn(true);
    console.log('Access token:', response.access_token);

    // Fetch user profile information
    try {
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      });
      const userInfoData = await userInfoResponse.json();
      setUserInfo(userInfoData); // Set user info in state
      console.log('User info:', userInfoData);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };


  // Step 4: Handle login request
  const handleLogin = () => {
    if (tokenClient) {
      tokenClient.requestAccessToken();
      console.log("Token ",tokenClient);
      
    } else {
      console.error('Token client not initialized');
    }
  };

  // Step 5: Access Google Calendar API to retrieve events
  const getCalendarEvents = async () => {
    if (!isSignedIn) {
      console.error('User is not signed in');
      return;
    }

    try {
      const response = await window.gapi.client.calendar.events.list({
        calendarId: 'primary',
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: 'startTime',
      });
      console.log('Events:', response.result.items);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  // Step 6: Render login and Calendar API request buttons
  return (
    <div>
      {isSignedIn ? (
        <>
          <button onClick={() => getCalendarEvents()}>Get Calendar Events</button>
          <button onClick={() => setIsSignedIn(false)}>Logout</button>
        </>
      ) : (
        <button onClick={handleLogin}>Login with Google</button>
      )}
    </div>
  );
};

export default GoogleCalendar;
