import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/system'
import { team, member,memberDeletor } from './Request'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAlert from '../Utilities/Alert'
import { ToastContainer, toast } from 'react-toastify';

const TeamInfo = () => {
    const { w_id, t_id } = useParams()
    const access_token = localStorage.getItem('access_token')
    const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST
    const [teamMembers, setTeamMembers] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        teamName: '',
        members: '',
        role: 'member',
        email: '',
        w_id: 0,
        t_id: 0,
        forward: false

    });
    const [open, setOpen] = useState(false)
    const [loading,setLoading]=useState(false)


    const fetchTeamMembers = async () => {
        if (w_id && t_id) {
            try {
                setLoading(true)
                await axios.get(`${dashboard_backend}/team/workspaces/${w_id}/${t_id}/members/`, { headers: { Authorization: `Bearer ${access_token}` } }).then((res) => {
                    if (res.status === 200) {
                        setTeamMembers(res.data)
                        setLoading(false)


                    }
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
    };

    useEffect(() => {
        fetchTeamMembers()
    }, [w_id, t_id, team])

    const InvitePeople = async () => {
        try {
            if (formData.email && formData.role) {
                setLoading(true)
                await member({ email: formData.email, role: formData.role }, w_id, t_id).then((res) => {
                    if (res && res.status === 201 || res.status === 200) {
                        fetchTeamMembers()
                        toast.success("Member Invited")
                        setOpen(false)
                        setLoading(false)
                    }
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                    
                })
            }
        } catch (error) {
            console.log(error);
            toast.error("Error")
            setLoading(false)

        }
    }

    const deleteMember = async(workspace_id,member_id)=>{
        try {
            setLoading(true)
            await memberDeletor(workspace_id,member_id).then((res)=>{
                if(res.status===204){
                    toast.success("Member Deleted")
                    fetchTeamMembers()
                    setLoading(false)
                }
            }).catch((err)=>{
                console.log(err)
                setLoading(false)
            })
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    


    return (
        <div>
            <ToastContainer/>
            {loading && <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>}
           { !loading && <>
            {teamMembers.length > 0 &&

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography>{teamMembers.length} Members in Team</Typography>
                    <Button variant="contained" onClick={() => setOpen(true)}>Add Members</Button>
                </Box>
            }
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add Member in your team"}
                </DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ width: '100%', mt: 2 }}>
                        <InputLabel htmlFor="email-input" sx={{ mb: 1 }}>Add emails</InputLabel>
                        <TextField
                            fullWidth
                            id="email-input"
                            type="email"
                            placeholder="Enter Email"
                            variant="outlined"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value, forward: e.target.value && 2 })}
                            size="small"
                        />

                        <InputLabel id="role-select-label" sx={{ mt: 2 }}>Role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            value={formData.role}
                            onChange={(e) => setFormData({ ...formData, role: e.target.value, forward: e.target.value && true })}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="member">Member</MenuItem>
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={InvitePeople} >Submit</Button>
                    <Button onClick={() => setOpen(false)} autoFocus>
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
            {teamMembers.length > 0 ? <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead >
                        <TableRow>

                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Role</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamMembers.length > 0 && teamMembers.map((member, index) => (
                            <TableRow
                                key={member.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >


                                <TableCell align="left">{member.email}</TableCell>

                                <TableCell align="left">{member.role}</TableCell>
                                <TableCell align="left">{member.status}</TableCell>
                                <TableCell align="left">{<DeleteAlert deleting={'Member'} name={member.email} controlDelete={() => deleteMember(w_id,member.id)}><DeleteIcon sx={{cursor: 'pointer' }}/></DeleteAlert>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'start', gap: 2, marginTop: 8 }}>
                <Box component="form" sx={{ width: '50%', mt: 2 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Add Member in your team</Typography>
                    <InputLabel htmlFor="email-input" sx={{ mb: 1 }}>Add emails</InputLabel>
                    <TextField
                        fullWidth
                        id="email-input"
                        type="email"
                        placeholder="Enter Email"
                        variant="outlined"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value, forward: e.target.value && 2 })}
                        size="small"
                    />

                    <InputLabel id="role-select-label" sx={{ mt: 2 }}>Role</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={formData.role}
                        onChange={(e) => setFormData({ ...formData, role: e.target.value, forward: e.target.value && true })}
                        size="small"
                        fullWidth
                    >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="member">Member</MenuItem>
                    </Select>
                </Box>
                <Button onClick={InvitePeople}>Invite people</Button>
            </Box>}
            </>}
        </div>
    )
}

export default TeamInfo