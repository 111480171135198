import axios from 'axios';
const backend = process.env.REACT_APP_BACKEND_HOST
const auth = process.env.REACT_APP_AUTH_HOST
// Create an Axios instance
const apiClient = axios.create({
    baseURL: `${backend}`, // Your API base URL
});

// Add a request interceptor
apiClient.interceptors.request.use(async (config) => {
    const access_token = localStorage.getItem('access_token');

    // Attach access_token to request headers
    if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// Add a response interceptor
apiClient.interceptors.response.use(response => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    // Check for 401 Unauthorized error
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        
        // Get the refresh token
        const refresh_token = localStorage.getItem('refresh_token');
        
        // Make a request to refresh the token
        try {
            console.log("Entered to this place",refresh_token)
            const response = await axios.post(`${auth}/api/token/refresh/`, { // Your refresh token endpoint
                refresh : refresh_token,
            });
            
            // Update access token in localStorage
            const new_access_token = response.data.access_token;
            const new_refresh_token = response.data.refresh_token;
            localStorage.setItem('access_token', new_access_token);
            localStorage.setItem('refresh_token', new_refresh_token);

            // Set the new access token in the original request headers
            originalRequest.headers['Authorization'] = `Bearer ${new_access_token}`;

            // Retry the original request
            return apiClient(originalRequest);
        } catch (refreshError) {
            // Handle error (e.g., refresh token expired or invalid)
            console.error('Refresh token request failed:', refreshError);
            // Optionally redirect to login or show a message
            return Promise.reject(refreshError);
        }
    }

    // If error is not 401 or retry failed, reject the promise
    return Promise.reject(error);
});

export default apiClient;
