import axios from 'axios'
import React, { useEffect } from 'react'

const People = () => {
  const workspaceId = JSON.parse(sessionStorage.getItem('organization_id'))[0].id
  
  // const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST

  const fetchTeamMembers = async ()=>{
    const response = await axios.get(`http://localhost:9000/team/workspaces/${workspaceId}/teams/members/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
    )
      console.log(response.data);
  }
  
  
  useEffect(() => {
    fetchTeamMembers()
  }, [])
  return (
    <div>People</div>
  )
}

export default People