import axios from "axios";

const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST
const API_URL = `${dashboard_backend}/api/`;

export const authenticateWithGoogle = async () => {
  return await axios.get(`${API_URL}auth/google/`);
};

export const fetchEvents = async () => {
  return await axios.get(`${API_URL}auth/events/`, { withCredentials: true });
};



