import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from '@react-oauth/google';
// import {store,persistor} from './Store/store'
import store from './Store/store'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { ToastContainer, toast } from 'react-toastify';
import { msalConfig } from './components/sign-in/AuthProvider';
const msalInstance = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
const client = process.env.REACT_APP_GOOGLE_CLIENT_ID







root.render(
  <React.StrictMode>
    <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <GoogleOAuthProvider clientId={client} >
    <MsalProvider instance={msalInstance}>
    <ToastContainer/>
    <App />

    </MsalProvider>
    </GoogleOAuthProvider>
    {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
