import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { Tab, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import PdfModal from "../../utilities/PdfModal";
import DeleteAlert from "../Utilities/Alert";
import { toast, ToastContainer } from "react-toastify";

const columns = [
  // { id: "source", label: "Source", minWidth: 50 },
  { id: "report", label: "Report", minWidth: 120 },
  {
    id: "tiem&date",
    label: "Time\u00a0&\u00a0Date",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "tags",
  //   label: "Tags",
  //   minWidth: 120,
  //   align: "left",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "owner",
    label: "Owner",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id:"View",
    label:"View",
    minWidth:100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Delete",
    label: "Delete",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Share",
    label: "Share",
    minWidth: 100,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  //   createData('India', 'IN', 1324171354, 3287263),
  {
    id: 1,
    source: (
      <img
        src="https://img.icons8.com/?size=100&id=Nja00lAQ9VQc&format=png&color=000000"
        height={"50px"}
        width={"50px"}
      />
    ),
    report: "Liznr Launch Discussion",
    "tiem&date": "27/10/2024 1:00:00 PM",
    score: 80,
    tags: "Launch",
    owner: "David",
    delete: <DeleteOutlineIcon />,
  },
];



export default function ReportTable({moms,pageUpdate}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pdfurl, setPdfUrl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const navigate = useNavigate();
  
  
  const pdfName = (path)=>{

    
    const final = path.split("/");
    return final[final.length-1];
    
  }
  const fetchPDF = async(file)=>{
    const file_name = pdfName(file);
    let updatedPath = file.replace(/^app\//, "").replaceAll("/", "\\");
    
    console.log("File name",file_name);
    try{

      await axios.post(
        `http://localhost:9000/moms/get-pdf/`,
        { file_path: updatedPath }, // Payload
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          responseType: "blob", // Include responseType here
        }
      ).then((res)=>{
        if(res.status===200){
          const url = URL.createObjectURL(res.data);
          setPdfUrl(url);
          setOpen(true);
          
        }
      }).catch((err)=>{
        console.log(err);
      })
    }catch(err){
      console.log(err);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (data) => {
    let id = data.id;
    navigate(`/topics/${id}`);
  };
  function formatToIndianDateTime(isoString) {
    // Parse the ISO string to a Date object
    const date = new Date(isoString);

    // Format the date and time in Indian format
    const indianFormatter = new Intl.DateTimeFormat('en-IN', {
        dateStyle: 'full', // Full date style (e.g., Monday, 9 December 2024)
        timeStyle: 'short', // Short time style (e.g., 1:28 PM)
        timeZone: 'Asia/Kolkata' // Indian Standard Time
    });
    const finalDate = indianFormatter.format(date).split("at");
    const dateFormat = ()=>{
      return (
        <>
        <Typography variant="body1">{finalDate[0]}</Typography>
        <p>{finalDate[1]}</p>
        </>
      )
    }
    
    return dateFormat();
}
const momDelete = async(id)=>{
 await axios.delete(`http://localhost:9000/moms/delete/${id}/`,{
    headers:{
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  }).then((res)=>{
    if(res.status===204 || res.status===200){
      pageUpdate();
      toast.success("Mom Deleted Successfully");
    }
  }).catch((err)=>{
    console.log(err);
    toast.error(err.response.data.detail);
  })
}


  return (
    <Paper sx={{ overflow: "hidden", margin: "10px 20px" }}>
      <ToastContainer/>
      <PdfModal open={open} setOpen={setOpen} pdfurl={pdfurl} />
      <TableContainer sx={{ maxHeight: 440, borderRadius: "10px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleClick(row)}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })} */}
              {moms && moms.map((mom) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={mom.id}>
                    
                    <TableCell align="left">{mom.title}</TableCell>
                    <TableCell align="left">{formatToIndianDateTime(mom.date)}</TableCell>
                    <TableCell align="left">{mom.owner}</TableCell>
                    <TableCell align="left" sx={{cursor:"pointer"}} onClick={()=>fetchPDF(mom.file_path)}><VisibilityIcon/></TableCell>
                    <TableCell align="left" sx={{cursor:"pointer"}}><DeleteAlert deleting={"MoM"} name={mom.title} controlDelete={()=>momDelete(mom.meet_id)} ><DeleteOutlineIcon/></DeleteAlert></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
