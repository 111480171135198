import { createSlice } from '@reduxjs/toolkit'

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    user : {
      name: '',
      email: '',
      image:'',
      id:null
    }
  },
  reducers: {
    setUserInfo: (state,action) => {
      state.user = action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer