import React from 'react';

const SuccessAnimation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="100"
    height="100"
  >
    {/* Circle Animation */}
    <circle
      cx="50"
      cy="50"
      r="45"
      stroke="#4CAF50"
      strokeWidth="4"
      fill="none"
      strokeLinecap="round"
      style={{
        strokeDasharray: 283, // 2 * Math.PI * 45 (circumference of the circle)
        strokeDashoffset: 283, // Hidden initially
        animation: 'circleAnimation 1s ease-out forwards',
      }}
    />

    {/* Checkmark */}
    <path
      d="M30 50 L45 65 L70 35"
      fill="none"
      stroke="#4CAF50"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        strokeDasharray: 70, // Approximate path length
        strokeDashoffset: 70, // Hidden initially
        animation: 'checkmarkAnimation 0.5s 1s ease-out forwards', // Delay to sync with circle
      }}
    />

    {/* Animation Styles */}
    <style>
      {`
        @keyframes circleAnimation {
          to {
            stroke-dashoffset: 0;
          }
        }
        
        @keyframes checkmarkAnimation {
          to {
            stroke-dashoffset: 0;
          }
        }
      `}
    </style>
  </svg>
);

export default SuccessAnimation;
