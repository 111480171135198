import React, { useEffect, useState } from 'react'
import SuccessAnimation from '../../utilities/svg'
import './AcceptInvitation.css'
import { Box } from '@mui/material'
import { Button } from 'react-bootstrap'
import liznr from '../../Assets/LiznrLight.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'

const AcceptInvitation = () => {
    const [searchParams] = useSearchParams();
    const workspace = searchParams.get('workspace'); // Get workspace name
    const team = searchParams.get('team');           // Get team name
    const token = searchParams.get('token');         // Get token
    const [accepted, setAccepted] = useState(false);
    const [error, setError] = useState('');
    const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST
    const navigate = useNavigate();
    const handleAccept = async () => {
        try {
            if (!token) {
              throw new Error('Token not found');
            }
            const response = await axios.get(
                `${dashboard_backend}/team/accept-invitation/${token}/`
              );
        //   alert(response.data.message);
        if (response.status === 200) {
            setAccepted(true);
        }
        } catch (error) {
          console.error(error);
          setError(error.response.data.message);
        }
      };
      

  return (
    <div className='main'>
        <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <img src={liznr} alt="" height='100px' />
        <h3>Invitation From Organization {workspace} to join {team} Team</h3>
        {accepted && !error && <div className='main-content'>
        <h1>Invitation Accepted</h1>
        <SuccessAnimation/>
        
        </div>}
        {!accepted && !error && <Button onClick={handleAccept}>Accept Invitation</Button>}
        {accepted || error ? <Button onClick={()=>navigate('/')}>Login</Button> : null}
        {error && <p>{error}</p>}
        
        </Box>
    </div>
  )
}

export default AcceptInvitation