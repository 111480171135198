import { createSlice } from '@reduxjs/toolkit'

export const workspaceSlice = createSlice({
  name: 'workspaceInfo',
  initialState: {
    hasWorkspace: false,
    workspaceDetails: []
  },
  reducers: {

    setWorkspace: (state,action) => {
      state.hasWorkspace = action.payload
    },
    setWorkspaceInfo:(state,action)=>{
      state.workspaceDetails = action.payload
    }
   
  },
})

// Action creators are generated for each case reducer function
export const { setWorkspace,setWorkspaceInfo } = workspaceSlice.actions

export default workspaceSlice.reducer