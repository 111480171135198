import { createSlice } from '@reduxjs/toolkit'

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: {
    authorized: false,
  },
  reducers: {
    authorize: (state,action) => {
      state.authorized = action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { authorize } = authorizationSlice.actions

export default authorizationSlice.reducer