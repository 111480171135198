import { Box, Container, Modal, Typography } from '@mui/material'
import React from 'react'

const PdfModal = ({open,setOpen,pdfurl}) => {   
    const handleClose = () => setOpen(false);
  return (
    <Container sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      >
         <Box sx={{width:"800px",height:"100%"}}>
          
          {pdfurl ? (
        <iframe
          src={pdfurl}
          title="PDF Viewer"
          width="100%"
          height="600px"
        ></iframe>
      ) : (
        <p>Loading PDF...</p>
      )}
        </Box>
      </Modal>
      </Container>

  )
}

export default PdfModal