// import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Box } from '@mui/material';
// import LiznrLogo from '../../Assets/LiznrLight.png';

// function Feedback() {
// 	const [info, setInfo] = useState({
// 		name: '',
// 		email: '',
// 		message: '',
// 		phone: '',
// 		uploadedFiles: [],
// 		filePreviews: [], // To store image previews
// 		buttonText: 'Submit',
// 	});

// 	const { name, email, message, phone, uploadedFiles, filePreviews, buttonText } = info;

// 	const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;

// 	// Handle form submission
// 	const handleSubmit = e => {
// 		e.preventDefault();
// 		setInfo({ ...info, buttonText: '...sending' });

// 		const formData = new FormData();
// 		formData.append('name', name);
// 		formData.append('email', email);
// 		formData.append('message', message);
// 		formData.append('phone', phone);

// 		uploadedFiles.forEach(file => {
// 			formData.append('uploadedFiles[]', file);
// 		});

// 		axios
// 			.post(`${dashboard_backend}/support/feedback/`, formData, {
// 				headers: { 'Content-Type': 'multipart/form-data' },
// 			})
// 			.then(res => {
// 				if (res.data.success) {
// 					toast.success('🦄 Thanks for your feedback!');
// 					setInfo({
// 						...info,
// 						name: '',
// 						phone: '',
// 						email: '',
// 						message: '',
// 						uploadedFiles: [],
// 						filePreviews: [],
// 						buttonText: 'Submitted',
// 					});
// 				}
// 			})
// 			.catch(err => {
// 				toast.error(err.response?.data?.error || 'Failed to send feedback. Please try again.');
// 				setInfo({ ...info, buttonText: 'Submit' });
// 			});
// 	};

// 	// Handle input change
// 	const handleChange = name => e => {
// 		setInfo({ ...info, [name]: e.target.value });
// 	};

// 	// Handle file uploads
// 	const handleFileUpload = e => {
// 		const files = Array.from(e.target.files);
// 		addFilesToState(files);
// 	};

// 	// Handle paste in the description
// 	const handlePaste = e => {
// 		e.preventDefault();
// 		const items = e.clipboardData.items;
// 		const pastedFiles = [];
// 		for (let i = 0; i < items.length; i++) {
// 			if (items[i].type.startsWith('image/')) {
// 				const file = items[i].getAsFile();
// 				pastedFiles.push(file);
// 			}
// 		}
// 		if (pastedFiles.length > 0) {
// 			addFilesToState(pastedFiles);
// 			toast.info(`${pastedFiles.length} screenshot(s) added.`);
// 		} else {
// 			toast.warning('No image detected in clipboard.');
// 		}
// 	};

// 	// Add files to state and generate previews
// 	const addFilesToState = files => {
// 		const newPreviews = [];
// 		files.forEach(file => {
// 			const reader = new FileReader();
// 			reader.onload = e => {
// 				newPreviews.push(e.target.result);
// 				// Update state after all previews are generated
// 				if (newPreviews.length === files.length) {
// 					setInfo(prevState => ({
// 						...prevState,
// 						uploadedFiles: [...prevState.uploadedFiles, ...files],
// 						filePreviews: [...prevState.filePreviews, ...newPreviews],
// 					}));
// 				}
// 			};
// 			reader.readAsDataURL(file);
// 		});
// 	};

// 	return (
// 		<Box
// 			sx={{
// 				display: 'flex',
// 				flexDirection: 'column',
// 				justifyContent: 'center',
// 				alignItems: 'center',
// 				height: '100vh',
// 			}}
// 		>
// 			<img src={LiznrLogo} height={'50px'} alt="" />

// 			<div className="form">
// 				<ToastContainer />
// 				<h1>Feedback Form</h1>

// 				<Form onSubmit={handleSubmit}>
// 					<Form.Group>
// 						<Form.Label>Name</Form.Label>
// 						<Form.Control
// 							value={name}
// 							onChange={handleChange('name')}
// 							type="text"
// 							placeholder="Enter your name"
// 							required
// 						/>
// 					</Form.Group>

// 					<Form.Group>
// 						<Form.Label>Phone</Form.Label>
// 						<Form.Control
// 							value={phone}
// 							onChange={handleChange('phone')}
// 							type="text"
// 							placeholder="Enter your phone number"
// 							required
// 						/>
// 					</Form.Group>

// 					<Form.Group>
// 						<Form.Label>Email Address</Form.Label>
// 						<Form.Control
// 							value={email}
// 							onChange={handleChange('email')}
// 							type="email"
// 							placeholder="Enter your email"
// 							required
// 						/>
// 					</Form.Group>

// 					<Form.Group>
// 						<Form.Label>Upload Files</Form.Label>
// 						<Form.Control
// 							type="file"
// 							multiple
// 							onChange={handleFileUpload}
// 							accept="image/*,application/pdf"
// 						/>
// 					</Form.Group>

// 					<Form.Group>
// 						<Form.Label>Description</Form.Label>
// 						<Form.Control
// 							onChange={handleChange('message')}
// 							onPaste={handlePaste}
// 							as="textarea"
// 							value={message}
// 							rows={3}
// 							placeholder="Enter your feedback or paste screenshots here"
// 							required
// 						/>
// 					</Form.Group>

// 					{/* Display Image Previews */}
// 					{filePreviews.length > 0 && (
// 						<div style={{ marginTop: '20px' }}>
// 							<h5>Preview:</h5>
// 							<div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
// 								{filePreviews.map((preview, index) => (
// 									<img
// 										key={index}
// 										src={preview}
// 										alt={`Preview ${index}`}
// 										style={{ width: '100px', height: '100px', objectFit: 'cover', border: '1px solid #ddd' }}
// 									/>
// 								))}
// 							</div>
// 						</div>
// 					)}

// 					<Button type="submit" variant="primary">
// 						{buttonText}
// 					</Button>
// 				</Form>
// 			</div>
// 		</Box>
// 	);
// }

// export default Feedback;

import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";
import LiznrLogo from "../../Assets/LiznrLight.png";

function Feedback() {
  const [info, setInfo] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    uploadedFiles: [],
    filePreviews: [],
    buttonText: "Submit",
  });

  const {
    name,
    email,
    message,
    phone,
    uploadedFiles,
    filePreviews,
    buttonText,
  } = info;

  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;

  const handleSubmit = (e) => {
    e.preventDefault();
    setInfo({ ...info, buttonText: "...sending" });

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("phone", phone);

    uploadedFiles.forEach((file) => {
      formData.append("uploadedFiles[]", file);
    });

    axios
      .post(`${dashboard_backend}/support/feedback/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("🦄 Thanks for your feedback!");
          setInfo({
            ...info,
            name: "",
            phone: "",
            email: "",
            message: "",
            uploadedFiles: [],
            filePreviews: [],
            buttonText: "Submitted",
          });
        }
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.error ||
            "Failed to send feedback. Please try again."
        );
        setInfo({ ...info, buttonText: "Submit" });
      });
  };

  const handleChange = (name) => (e) => {
    setInfo({ ...info, [name]: e.target.value });
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    addFilesToState(files);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const items = e.clipboardData.items;
    const pastedFiles = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.startsWith("image/")) {
        const file = items[i].getAsFile();
        pastedFiles.push(file);
      }
    }
    if (pastedFiles.length > 0) {
      addFilesToState(pastedFiles);
      toast.info(`${pastedFiles.length} screenshot(s) added.`);
    } else {
      toast.warning("No image detected in clipboard.");
    }
  };

  const addFilesToState = (files) => {
    const newPreviews = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        newPreviews.push(e.target.result);
        if (newPreviews.length === files.length) {
          setInfo((prevState) => ({
            ...prevState,
            uploadedFiles: [...prevState.uploadedFiles, ...files],
            filePreviews: [...prevState.filePreviews, ...newPreviews],
          }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100vh", // Ensure the box height matches the viewport
        width: "100%",
        backgroundColor: "#FFF",
        padding: "20px",
        boxSizing: "border-box",
        overflow: "hidden", // Prevent scrollbars
      }}
    >
      {/* <Box
        sx={{
          position: "absolute",
          top: "10px",
          zIndex: 10,
          backgroundColor: "#FFF",
          padding: "5px",
          borderRadius: "8px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img src={LiznrLogo} height="30px" alt="Liznr Logo" />
      </Box> */}
      <ToastContainer />
      <h1
        style={{
          fontSize: "1.5rem",
          // color: "#333",
          marginBottom: "10px",
          color: "#05294b !important",
          marginTop: "0px",
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif !important`,
        }}
      >
        Feedback Form
      </h1>

      <Box
        sx={{
          backgroundColor: "#c5a5e8",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          width: "90%",
          maxWidth: "400px", // Ensure form width remains compact
          marginBottom: "30px",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label style={{ fontSize: "0.8rem" }}>Name</Form.Label>
            <Form.Control
              value={name}
              onChange={handleChange("name")}
              type="text"
              placeholder="Enter your name"
              required
              style={{
                marginBottom: "10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ fontSize: "0.8rem" }}>Phone</Form.Label>
            <Form.Control
              value={phone}
              onChange={handleChange("phone")}
              type="text"
              placeholder="Enter your phone number"
              required
              style={{
                marginBottom: "10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ fontSize: "0.8rem" }}>
              Email Address
            </Form.Label>
            <Form.Control
              value={email}
              onChange={handleChange("email")}
              type="email"
              placeholder="Enter your email"
              required
              style={{
                marginBottom: "10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ fontSize: "0.8rem" }}>Upload Files</Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={handleFileUpload}
              accept="image/*,application/pdf"
              style={{
                marginBottom: "10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ fontSize: "0.8rem" }}>Description</Form.Label>
            <Form.Control
              onChange={handleChange("message")}
              onPaste={handlePaste}
              as="textarea"
              value={message}
              rows={3}
              placeholder="Enter your feedback or paste screenshots here"
              required
              style={{
                marginBottom: "10px",
                borderRadius: "5px",
                fontSize: "0.8rem",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Form.Group>

          {/* Display Image Previews */}
          {filePreviews.length > 0 && (
            <div style={{ marginTop: "18px" }}>
              <h5>Preview:</h5>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {filePreviews.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      border: "1px solid #ddd",
                      borderRadius: "6px",
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <Button
            type="submit"
            variant="primary"
            style={{
              display: "block",
              margin: "0 auto",
              padding: "7px 60px",
              marginTop: "12px",
              borderRadius: "15px",
              fontSize: "0.8rem",
              backgroundImage:
                "linear-gradient(90deg, #DE00FF 0%, #8000FF 100%)",
            }}
          >
            {buttonText}
          </Button>
        </Form>
      </Box>
    </Box>
  );
}

export default Feedback;
