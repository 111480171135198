import React from "react";
import BusinessIcon from "@mui/icons-material/Business";
import Diversity3Icon from "@mui/icons-material/Diversity3";

import axios from "axios";
import { useState, useEffect } from "react";

import "./reports.css";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { AppProvider } from "@toolpad/core/AppProvider";
import MyReport from "./MyReport";
import ReportTable from "./ReportTable";
import { useSelector } from "react-redux";

const Reports = () => {
  const [value, setValue] = useState(0);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(""); // For dropdown value
  const [moms, setMoms] = useState([]);

  console.log(`value of teams ${teams}`);
  // const workspaceData = useSelector(
  //   (state) => state.workspace.workspaceDetails
  // );
  const workspaceData = JSON.parse(sessionStorage.getItem("organization_id"));

  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const access_token = localStorage.getItem("access_token");

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };
  const workspaceId = workspaceData && workspaceData.length > 0 && workspaceData[0].id;
  const fetchTeams = async () => {
    // setLoading(true);
    await axios
      .get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.data);
          console.log(`getting data from Teams ${res.data}`);
          //   setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };
  useEffect(() => {
    if (workspaceData && workspaceData.length > 0) {
      fetchTeams();
    }
  }, []);
  const handleChange = (event) => {
    setSelectedTeam(event.target.value);
    console.log("Selected Team:", event.target.value); // Log the selected team
  };

  // MOM
  const fetchMoms = async () => {
    await axios.get(`${dashboard_backend}/moms/mom-list/`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setMoms(res.data);
        console.log(`getting data from Teams ${res.data}`);
        
        //   setLoading(false);
        console.log(`getting data from Teams ${res.data}`);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  useEffect(()=>{
   fetchMoms();
  },[])
  
  const pageUpdate = ()=>{
  fetchMoms();
  }

  return (
    <Container>
      <Container
        sx={{
          height: "60px",
          width: "100%",
          display: "flex",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Box
          className="reports"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {workspaceData && workspaceData.length > 0 && (
            <SvgIcon>
              <path
                d="M7.5 11H4.6c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C3 11.76 3 12.04 3 12.6V21m13.5-10h2.9c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C21 11.76 21 12.04 21 12.6V21m-4.5 0V6.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C14.98 3 14.42 3 13.3 3h-2.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C7.5 4.52 7.5 5.08 7.5 6.2V21M22 21H2m9-14h2m-2 4h2m-2 4h2"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </SvgIcon>
          )}
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontSize: "16px", // Increased text size
              marginLeft: "10px",
            }}
          >
            {workspaceData && workspaceData.length > 0 ? workspaceData[0].name : "Reports"}
          </Typography>
          {/* <Typography variant="body2">
            {workspaceData.length > 0 ? workspaceData[0].name : "Reports"}
          </Typography> */}

          <FormControl
            sx={{
              m: 2,
              minWidth: 200,
              height: "40px",
              border: "1px solid #e0e0e0",
              borderRadius: "5px",
              padding: "4px",
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
            variant="standard"
          >
            <SvgIcon>
              <path
                d="M16 7c0-.93 0-1.395-.102-1.776a3 3 0 0 0-2.121-2.122C13.395 3 12.93 3 12 3c-.93 0-1.395 0-1.777.102a3 3 0 0 0-2.12 2.122C8 5.605 8 6.07 8 7M5.2 21h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C22 19.48 22 18.92 22 17.8v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 7 19.92 7 18.8 7H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 8.52 2 9.08 2 10.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 21 4.08 21 5.2 21Z"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </SvgIcon>

            <Select
              //   value={10}
              // onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "100%",
                color: "#333", // Matching text color
                fontSize: "15px", // Consistent text size
                fontWeight: 400, // Same weight as other text
                fontStyle: "normal", // Prevent italic or slanted text
              }}
              labelId="team-dropdown-label"
              value={selectedTeam}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em style={{ fontStyle: "normal", paddingLeft: "30px" }}>
                  {" "}
                  All Teams
                </em>{" "}
                {/* Changed default option */}
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.team_name}
                </MenuItem>
              ))}

              {/* <MenuItem value ={}></MenuItem> */}
              {/* <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </FormControl>
        </Box>
      </Container>
      <AppProvider>
        <Divider />
        <Box sx={{ width: "100%", p: "10px 20px" }}>
          <Tabs
            onChange={handleTab}
            value={value}
            aria-label="Tabs where selection follows focus"
            selectionFollowsFocus
          >
            <Tab label="My Report" />
            <Tab label="Shared With Me" />
            <Tab label="Incomplete" />
          </Tabs>
        </Box>
        <MyReport />
        <ReportTable moms={moms} pageUpdate = {pageUpdate} />
      </AppProvider>
    </Container>
  );
};

export default Reports;
