import {
  Box,
  Checkbox,
  Container,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import IOSSwitch from "../Utilities/Switch";

const MyReport = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <Container sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Box
          sx={{
            width: "220px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Checkbox {...label} defaultChecked />
          <span>Meetings</span>
          <Checkbox {...label} />
          <span>Topics</span>
        </Box>
        <Box
          sx={{
            width: "150px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <span>Group</span>
          <IOSSwitch />
        </Box>
        {/* <Box
          sx={{
            width: "100px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            id="standard-select-currency"
            variant="standard"
            select
            // label="Select"
            defaultValue="EUR"
            // helperText="Please select your currency"
            size="medium"
            sx={{
              padding: "2px",
              // '& .MuiInputBase-input': {
              //     fontSize: '12px', // Change the font size here
              // },
            }}
          >
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="USD" sx={{ fontSize: "12px" }}>
              USD
            </MenuItem>
            <MenuItem value="BTC" sx={{ fontSize: "12px" }}>
              BTC
            </MenuItem>
            <MenuItem value="ETH" sx={{ fontSize: "12px" }}>
              ETH
            </MenuItem>
          </TextField>
        </Box>
        <Box
          sx={{
            width: "80px",
            border: "1px solid grey",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Select
            value={10}
            // onChange={handleChange}
            variant="standard"
            size="small"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              "& .MuiInputBase-input": {
                borderBottom: "none",
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </Box> */}
      </Box>
    </Container>
  );
};

export default MyReport;
