import React from "react";
import axios from "axios";
import Message from "../Utilities/Message";
import { toast } from "react-toastify";

let access_token = localStorage.getItem("access_token");
const backend = process.env.REACT_APP_BACKEND_HOST;
const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;

const workspace = async (data) => {
  try {
    const response = await axios
      .post(`${dashboard_backend}/team/workspaces/`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    if (response.status === 200 || response.status === 201) {
      toast.success("Workspace created successfully");
      localStorage.setItem("organization", true);
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

const team = async (data, workspace_id) => {
  try {
    const response = await axios.post(
      `${dashboard_backend}/team/workspaces/${workspace_id}/team/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    if (response.status === 200 || response.status === 201) {
      toast.success("Team created successfully");
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};

const member = async (data, workspace_id, team_id) => {
  try {
    const response = await axios.post(
      `${dashboard_backend}/team/workspaces/${workspace_id}/${team_id}/members/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};

// For delete
const deletor = async (workspace_id) => {
  try {
    const response = await axios.delete(
      `${dashboard_backend}/team/workspaces/${workspace_id}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    if (response.status === 200 || response.status === 204) {
      toast.success("Organization deleted successfully");
      localStorage.setItem("organization", false);
      sessionStorage.removeItem("organization_id");
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};

const teamDeletor = async (workspace_id, team_id) => {
  try {
    const response = await axios.delete(
      `${dashboard_backend}/team/workspaces/${workspace_id}/team/${team_id}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    if (response.status === 200 || response.status === 201) {
      toast.success("Team deleted successfully");
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};

const memberDeletor = async (workspace_id, member_id) => {
  try {
    const response = await axios.delete(
      `${dashboard_backend}/team/workspaces/${workspace_id}/teams/${member_id}/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    if (response.status === 200 || response.status === 201) {
      toast.success("Member deleted successfully");
    }
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log(error);
  }
};

export { workspace, team, member, deletor, teamDeletor, memberDeletor };