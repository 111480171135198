
/*global chrome*/
import * as React from 'react';
import { createTheme, extendTheme, styled } from '@mui/material/styles';

import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LayersIcon from '@mui/icons-material/Layers';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { AppProvider } from '@toolpad/core/AppProvider';

import { DashboardLayout } from '@toolpad/core/DashboardLayout';


import axios from 'axios';
import { Outlet, useNavigate } from 'react-router-dom';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SettingsIcon from '@mui/icons-material/Settings';

import { authorize } from '../Store/Slices/AuthorizationSlice'
import {setUserInfo} from '../Store/Slices/UserInfoSlice'


// logo
import liznrLight from '../Assets/LiznrLight.png';
import liznrDark from '../Assets/LiznrDark.png';
import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useColorModes } from '@coreui/react';
import { Suspense } from 'react';
import { ThemeProvider } from '@emotion/react';




const NAVIGATION = [
  {
    segment: 'reports',
    title: 'Reports',
    icon: <BarChartIcon />,
    
  },
  {
    segment: 'organization',
    title: 'Add People',
    icon: <PersonAddIcon />,
    
  },
  
  {
    kind: 'divider',
  },
  {
    segment: 'calendar',
    title: 'Calendar',
    icon: <CalendarTodayIcon />,
  },
  {
    kind: 'divider',
  },
  {
      
      segment: 'teach',
      title: 'Train Liznr',
      icon: <LocalLibraryIcon />,
    },
  {
      segment: 'integration',
      title: 'Integration',
      icon: <IntegrationInstructionsIcon />,
    },
    
  {
    kind: 'divider',
  },
  {
      segment: 'support',
      title: 'Support',
      icon: <SupportAgentIcon />,
    },
    {
      kind: 'divider',
    },
    {
      segment: 'manage',
      title: 'Manage Account',
      icon: <SettingsIcon/>,
    },
 
];

const demoTheme = extendTheme({
  colorSchemes: { light: true, dark: true },
  colorSchemeSelector: 'class',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});




function useDemoRouter(initialPath = '') {
  const navigation = useNavigate();
  const [pathname, setPathname] = React.useState(initialPath);

  const router = React.useMemo(() => ({
    pathname,
    searchParams: new URLSearchParams(pathname),
    
    navigate: (path) => {
      setPathname(path); // Update local pathname state
      navigation(path);  // Perform actual navigation
      console.log(path);
      
    },
  }), [pathname]);

  return router;
}



const Skeleton = styled('div')(({ theme, height }) => ({
  backgroundColor: theme.palette.action.hover,
  borderRadius: theme.shape.borderRadius,
  height,
  content: '" "',
}));

const customTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: '#F9F9FE',
          paper: '#EEEEF9',
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: '#2A4364',
          paper: '#112E4D',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});


export default function DashboardLayoutBasic(props) {
 
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [compo,setCompo] = React.useState('')
  const navigate = useNavigate();
  const [isLightTheme, setIsLightTheme] = React.useState(true); // Track if the light theme is active
  const userInfo = useSelector(state=>state.userInfo)
  const hasWorkspace = useSelector(state=>state.workspace.hasWorkspace)
  const [session, setSession] = React.useState({
    
  }); 
  const dispatch = useDispatch()
  // setSession(userInfo)
  const { window } = props;

  // API
  const backend = process.env.REACT_APP_BACKEND_HOST
  
  
// const loginControl = async()=>{
   
//     try {

//       const access_token = localStorage.getItem('access_token')

//       const response = await axios.get('https://localhost:8000/user/',{
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${access_token}`
//         },
//       });
//       // setSession({
//       //   user: {
//       //     name: response.data.username,
//       //     email: response.data.email,
//       //     image: response.data.picture,
//       //   },
//       // })
      
//     } catch (error) {
//       const refresh_token = localStorage.getItem('refresh_token')
      
      
    
//       if(error.response && error.response.status === 401 && !refresh_token){
//         navigate('/sign-in')
//       }
//       try{
//         if(refresh_token !== null){
//           const response = await axios.post('https://localhost:9000/api/token/refresh/',{
//             refresh: refresh_token
//           })
          
          
          
//           if(response && response.status === 200){
          
//             localStorage.setItem('access_token', response.data.access)
//             localStorage.setItem('refresh_token', response.data.refresh)
//             loginControl()
//           }
         
  
//         }
//         else{
//           navigate('/sign-in')
//         }
  
//       }catch(error){
//         console.log("Internal server error",error);
//         // navigate('/sign-in')  
        
//       }     
     
      

//     }
    
// }

//   React.useEffect(() => {
//    loginControl();
   
   
//   }, []);

  

  const router = useDemoRouter('');

  // Login
  const loginControl = async()=>{
   
    try {

      const access_token = localStorage.getItem('access_token')
      const picture = (localStorage.getItem('picture')).toString()
      
      

      const response = await axios.get(`${backend}/user/`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        },
      });

      if(response && response.status === 200){
        
        setIsAuthenticated(true)
        dispatch(authorize(true))
        dispatch(setUserInfo({
          name: response.data.username,
          email: response.data.email,
          image: picture,
          id:response.data.id

        }))
        
      }
    } catch (error) {
      const refresh_token = localStorage.getItem('refresh_token')
      
    
      if(error.response && error.response.status === 401 && !refresh_token){
        navigate('/sign-in')
        setIsAuthenticated(false)
      }
      try{
        if(refresh_token !== null){
          const response = await axios.post(`${backend}/api/token/refresh/`,{
            refresh: refresh_token
          })
          
          
          
          if(response && response.status === 200){
          
            localStorage.setItem('access_token', response.data.access)
            localStorage.setItem('refresh_token', response.data.refresh)
            setIsAuthenticated(true)
          }
         
  
        }
        else{
          navigate('/sign-in')
          setIsAuthenticated(false)
        }
  
      }catch(error){
        console.log("Internal server error",error);
        
        
        setIsAuthenticated(false) 
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        navigate('/sign-in') 
        
        
      }     
     
      

    }
    
}

  React.useEffect(()=>{
   loginControl()
   
  },[])

  async function logoutFromWebsite() {
    const extensionId = "jboofaeiajhcbcgfpklmoooedclfggim";  // Replace with your extension's ID
    
    try {
      const response = await chrome.runtime.sendMessage(extensionId, { type: 'LOGOUT' });
      console.log('Logout sync response from extension:', response);
    } catch (error) {
      console.error('Failed to send logout sync to extension:', error);
    }
  }
  
 

  const logoutControl = async()=>{
    const refresh = localStorage.getItem('refresh_token')
    const access = localStorage.getItem('access_token')
    if(!refresh){
      navigate('/')
    }
    // logoutFromWebsite();
    try{
    await axios.post(`${backend}/logout/`,{
      refresh: refresh},{headers : { 'Authorization': `Bearer ${access}` }}).then((res)=>{
        
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('calendar_access_token')
        localStorage.removeItem('calendar_refresh_token')
        localStorage.removeItem('organization')
        localStorage.removeItem('organization_id')
        setSession(null)
        setIsAuthenticated(false)
        navigate('/sign-in')
      })

    }catch(error){
      console.log(error);
      navigate('/sign-in')
    }
  }

  const authentication = React.useMemo(() => {
    
    return {
      signIn: () => {
        navigate('/sign-in');
        
      },
      signOut: () => {
        logoutControl();
      },
    };
  }, []);




 


  // Remove this const when copying and pasting into your project.
  const demoWindow = window ? window() : undefined;

  React.useEffect(()=>{
    // setIsLightTheme(localStorage.getItem('toolpad-mode') === 'light');
    console.log(localStorage.getItem('toolpad-mode'));
    
    
    
  },[])




 
 
  const newNavigation = NAVIGATION.map((item, index) => {
    if (localStorage.getItem('organization') && item.segment === 'organization') {
      // Return a new object with the updated icon
      return { ...item, icon: <CorporateFareIcon />,title: 'Organization' };
    }
    // Return the item as-is if it doesn’t meet the condition
    return item;
  });
  

  const [theme, setTheme] = React.useState(() => {
    return localStorage.getItem('toolpad-mode') || 'light';
  });

  // Toggle theme and store it in localStorage
  // const toggleTheme = () => {
  //   const newTheme = theme === 'light' ? 'dark' : 'light';
  //   localStorage.setItem('theme', newTheme); // Save to localStorage
    
  //   // Dispatch a custom event to inform other parts of the app
  //   window.dispatchEvent(new Event('storage'));
  // };

  // // Listen for the 'storage' event and update theme accordingly
  // React.useEffect(() => {
  //   const handleStorageChange = () => {
  //     const updatedTheme = localStorage.getItem('theme');
  //     setTheme(updatedTheme); // Update the state with the new theme from localStorage
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  
  
  
  return (
    <ThemeProvider theme={demoTheme} >
    <AppProvider
      session={userInfo}
      authentication={authentication}
      navigation={!JSON.parse(localStorage.getItem('organization')) ? NAVIGATION : newNavigation}
      router={router}
      theme={demoTheme}
      // window={demoWindow}
      branding={{
        title: '',
        logo: isLightTheme ? (
          <img src={liznrLight} alt="logo" />
        ) : (
          <img src={liznrDark} alt="logo" />
        ),
      }}
    >
      {isAuthenticated ?<DashboardLayout
      // navigation={!JSON.parse(localStorage.getItem('organization')) ? NAVIGATION : newNavigation}
      defaultSidebarCollapsed={true}
      sx={{
        
        '& .css-hikx3c-MuiDrawer-docked .MuiDrawer-paper': {
          width:'220px'
        },
        '& .css-1tm471f .MuiDrawer-root': {
          width: '220px',
          
        },
        '& .css-hikx3c-MuiDrawer-docked':{
          width: '220px'
        },
        
      }}
      
      >
       
       
       <Outlet/>
        
      </DashboardLayout>:  <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>}
          
    </AppProvider>
    </ThemeProvider>
  );
}