// /*global chrome*/
// import * as React from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Checkbox from "@mui/material/Checkbox";
// import CssBaseline from "@mui/material/CssBaseline";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Divider from "@mui/material/Divider";
// import FormLabel from "@mui/material/FormLabel";
// import FormControl from "@mui/material/FormControl";
// import Link from "@mui/material/Link";
// import TextField from "@mui/material/TextField";
// import Typography from "@mui/material/Typography";
// import Stack from "@mui/material/Stack";
// import MuiCard from "@mui/material/Card";
// import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
// import ForgotPassword from "./ForgotPassword";
// import getSignInTheme from "./theme/getSignInTheme";

// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { GoogleLogin } from "@react-oauth/google";
// import { jwtDecode } from "jwt-decode";

// import { useMsal } from "@azure/msal-react";

// import { useGoogleLogin } from "@react-oauth/google";

// import LiznrDark from "../../Assets/LiznrDark.png";
// import LiznrLogo from "../../Assets/LiznrLight.png";
// import google from "../../Assets/google.svg";
// import microsoft from "../../Assets/microsoft.svg";

// import "./logo.css";

// const Card = styled(MuiCard)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignSelf: "center",
//   width: "100%",
//   maxWidth: "500px", // Increased width of the card
//   padding: theme.spacing(4),
//   gap: theme.spacing(2),
//   margin: "auto",
//   [theme.breakpoints.up("sm")]: {
//     maxWidth: "450px",
//   },
//   boxShadow:
//     "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
//   ...theme.applyStyles("dark", {
//     boxShadow:
//       "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
//   }),
// }));

// const SignInContainer = styled(Stack)(({ theme }) => ({
//   height: "100vh",
//   // padding: 20,
//   // backgroundImage:
//   //   "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
//   backgroundColor: "white", // Page background color
//   // backgroundRepeat: "no-repeat",
//   display: "flex",
//   justifyContent: "center", // Center the content vertically
//   alignItems: "center", // Center the content horizontally
//   // ...theme.applyStyles("dark", {
//   //   backgroundImage:
//   //     "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
//   // }),
// }));
// const CustomCard = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   gap: theme.spacing(4),
//   padding: theme.spacing(4),
//   boxShadow: "none", // Remove box shadow
//   border: "none", // Remove border
// }));

// const CustomButton = styled(Button)(({ theme }) => ({
//   width: "300px", // Increased width
//   height: "50px", // Increased height
//   textTransform: "none", // Keep text casing as-is
//   fontSize: "1rem", // Adjust font size
//   border: "1px solid #dadce0", // Button border style
//   borderRadius: "5px", // Slightly rounded corners
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "#f5f5f5", // Light background for buttons
//   "&:hover": {
//     backgroundColor: "#e0e0e0", // Slightly darker on hover
//   },
// }));

// export default function SignIn() {
//   const [mode, setMode] = React.useState("light");
//   const [showCustomTheme, setShowCustomTheme] = React.useState(true);
//   const defaultTheme = createTheme({ palette: { mode } });
//   const SignInTheme = createTheme(getSignInTheme(mode));
//   const [emailError, setEmailError] = React.useState(false);
//   const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
//   const [passwordError, setPasswordError] = React.useState(false);
//   const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
//   const [open, setOpen] = React.useState(false);

//   const [isSignedIn, setIsSignedIn] = React.useState(false);
//   const [tokenClient, setTokenClient] = React.useState(null);
//   const [userInfo, setUserInfo] = React.useState(null);

//   const navigate = useNavigate();
//   const backend = process.env.REACT_APP_BACKEND_HOST;
//   const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
//   const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
//   const secret_id = process.env.REACT_APP_GOOGLE_SECRET_ID;
//   const redirect_uri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

//   // This code only runs on the client side, to determine the system color preference
//   React.useEffect(() => {
//     // Check if there is a preferred mode in localStorage
//     const savedMode = localStorage.getItem("themeMode");
//     if (savedMode) {
//       setMode(savedMode);
//     } else {
//       // If no preference is found, it uses system preference
//       const systemPrefersDark = window.matchMedia(
//         "(prefers-color-scheme: dark)"
//       ).matches;
//       setMode(systemPrefersDark ? "dark" : "light");
//     }
//   }, []);

//   // This is for google login

//   // React.useEffect(() => {
//   //   const initializeGoogleClient = () => {
//   //     const client = window.google.accounts.oauth2.initTokenClient({
//   //       client_id: '530303062762-2pnen77012a0tadp0ekpv61a9pdf0bce.apps.googleusercontent.com', // Replace with your client ID
//   //       scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
//   //       callback: handleAuthResponse,
//   //     });
//   //     setTokenClient(client);
//   //   };

//   //   if (window.google && window.google.accounts && window.google.accounts.oauth2) {
//   //     initializeGoogleClient();
//   //   }
//   // }, []);

//   // const handleAuthResponse = async (response) => {
//   //   if (response.error) {
//   //     console.error('Authentication failed:', response.error);
//   //     return;
//   //   }
//   //   setIsSignedIn(true);
//   //   console.log('Access token:', response.access_token);

//   //   // Fetch user profile information
//   //   try {
//   //     const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
//   //       headers: {
//   //         Authorization: `Bearer ${response.access_token}`,
//   //       },
//   //     });
//   //     const userInfoData = await userInfoResponse.json();
//   //     setUserInfo(userInfoData); // Set user info in state
//   //     console.log('User info:', userInfoData);
//   //   } catch (error) {
//   //     console.error('Error fetching user info:', error);
//   //   }
//   // };

//   // const handleGoogleLogin = () => {
//   //   if (tokenClient) {
//   //     tokenClient.requestAccessToken();
//   //     console.log("Token ",tokenClient);

//   //   } else {
//   //     console.error('Token client not initialized');
//   //   }
//   // };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const data = new FormData(event.currentTarget);
//       const response = await axios.post(`${backend}/login/`, {
//         username: data.get("email"),
//         password: data.get("password"),
//       });
//       localStorage.setItem("access_token", response.data.access);
//       localStorage.setItem("refresh_token", response.data.refresh);
//       console.log(response);

//       navigate("/");
//     } catch (error) {
//       console.log("error while login", error);
//       navigate("/sign-in");
//     }
//   };

//   const validateInputs = () => {
//     const email = document.getElementById("email");
//     const password = document.getElementById("password");

//     let isValid = true;

//     if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
//       setEmailError(true);
//       setEmailErrorMessage("Please enter a valid email address.");
//       isValid = false;
//     } else {
//       setEmailError(false);
//       setEmailErrorMessage("");
//     }

//     if (!password.value || password.value.length < 6) {
//       setPasswordError(true);
//       setPasswordErrorMessage("Password must be at least 6 characters long.");
//       isValid = false;
//     } else {
//       setPasswordError(false);
//       setPasswordErrorMessage("");
//     }

//     return isValid;
//   };

//   async function sendLoggedInMessageToExtension(access, refresh) {
//     try {
//       const response = await chrome.runtime.sendMessage(
//         "jboofaeiajhcbcgfpklmoooedclfggim",
//         { type: "LOGIN", access_token: access, refresh_token: refresh }
//       );
//       console.log("Response from extension:", response);
//     } catch (error) {
//       console.error("Error sending message to extension:", error);
//     }
//   }

//   async function checkLoginStatusFromExtension() {
//     const extensionId = "jboofaeiajhcbcgfpklmoooedclfggim"; // Replace with your extension's ID

//     try {
//       const response = await chrome.runtime.sendMessage(extensionId, {
//         type: "CHECK_LOGIN",
//       });

//       if (response && response.status === "success") {
//         console.log("User is logged in on the extension");
//       } else {
//         console.log("User is not logged in on the extension");
//       }
//     } catch (error) {
//       console.error("Failed to check login status from extension:", error);
//     }
//   }

//   const handleSuccess = async (data) => {
//     try {
//       console.log(data);
//       if (data) {
//         localStorage.setItem("picture", data.picture);
//       }
//       await axios
//         .post(`${backend}/auth/google/`, {
//           email: data.email,
//           name: data.name,
//         })
//         .then((res) => {
//           const { access, refresh } = res.data;

//           // Store tokens in localStorage or sessionStorage
//           localStorage.setItem("access_token", access);
//           localStorage.setItem("refresh_token", refresh);

//           // notifyExtensionLogin(access);
//           sendLoggedInMessageToExtension(access, refresh);

//           navigate("/");
//         });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const { instance } = useMsal();
//   // Microsoft
//   const handleLogin = async () => {
//     await instance
//       .loginPopup(["openid", "profile", "User.Read"])
//       .then((response) => {
//         console.log(response.account.username);
//         handleSuccess({
//           email: response.account.username,
//           name: response.account.name,
//         });
//       })
//       .catch((e) => {
//         console.error(e);
//       });
//   };
//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         // Exchange the authorization code for an access token
//         const { code } = tokenResponse;

//         const tokenResponseData = await axios.post(
//           "https://oauth2.googleapis.com/token",
//           {
//             code: code,
//             client_id: client_id,
//             client_secret: secret_id,
//             redirect_uri: redirect_uri, // Use the same URI as in Google Cloud Console
//             grant_type: "authorization_code",
//           }
//         );

//         const accessToken = tokenResponseData.data.access_token;

//         // Fetch user info from Google's People API
//         const profileResponse = await axios.get(
//           "https://www.googleapis.com/oauth2/v2/userinfo",
//           {
//             headers: { Authorization: `Bearer ${accessToken}` },
//           }
//         );

//         handleSuccess(profileResponse.data);
//       } catch (error) {
//         console.error("Error fetching user profile:", error);
//       }
//     },
//     onError: () => {
//       console.error("Google login failed");
//       // Handle login errors here
//     },
//     flow: "auth-code", // Use 'auth-code' for the authorization code flow
//   });

//   return (
//     <>
//       <div className="sign-in-container">
//         <img src={LiznrLogo} alt="Liznr Logo" className="liznr-logo" />

//         <ThemeProvider theme={createTheme({ palette: { mode: "light" } })}>
//           <CssBaseline />
//           <SignInContainer
//             direction="column"
//             justifyContent="center"
//             sx={{ marginBottom: "300px" }}
//           >
//             {/* <Card variant="outlined"> */}
//             <Typography
//               component="h1"
//               variant="h4"
//               sx={{
//                 fontFamily: `"avertastd", sans-serif`, // Font style applied here
//                 color: "#0f203e",
//                 marginTop: "25px !important",
//                 margin: "1px auto 10px",
//                 lineHeight: "30px",
//                 fontSize: "20px",
//                 justifyContent: "center",
//                 fontWeight: "550",
//                 textAlign: "center",
//               }}
//             >
//               Sign In
//             </Typography>
//             <Box
//               component="form"
//               onSubmit={handleSubmit}
//               noValidate
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: "100%",
//                 gap: 2,
//                 maxWidth: "400px", // Ensures buttons stay within a fixed width

//                 height: "18px",
//               }}
//             >
//               {/* <FormControl>
//               <FormLabel htmlFor="email">Email</FormLabel>
//               <TextField
//                 error={emailError}
//                 helperText={emailErrorMessage}
//                 id="email"
//                 type="email"
//                 name="email"
//                 placeholder="your@email.com"
//                 autoComplete="email"
//                 autoFocus
//                 required
//                 fullWidth
//                 variant="outlined"
//                 color={emailError ? "error" : "primary"}
//                 sx={{ ariaLabel: "email" }}
//               />
//             </FormControl> */}
//               {/* <FormControl>
//               <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//                 <FormLabel htmlFor="password">Password</FormLabel>
//                 <Link
//                   component="button"
//                   onClick={handleClickOpen}
//                   variant="body2"
//                   sx={{ alignSelf: "baseline" }}
//                 >
//                   Forgot your password?
//                 </Link>
//               </Box>
//               <TextField
//                 error={passwordError}
//                 helperText={passwordErrorMessage}
//                 name="password"
//                 placeholder="••••••"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 autoFocus
//                 required
//                 fullWidth
//                 variant="outlined"
//                 color={passwordError ? "error" : "primary"}
//               />
//             </FormControl> */}
//               {/* <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             /> */}
//               {/* <ForgotPassword open={open} handleClose={handleClose} /> */}
//               {/* <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               onClick={validateInputs}
//             >
//               Sign in
//             </Button> */}
//               {/* <Typography sx={{ textAlign: "center" }}>
//               Don&apos;t have an account?{" "}
//               <span>
//                 <Link
//                   href="/register"
//                   variant="body2"
//                   sx={{ alignSelf: "center" }}
//                 >
//                   Sign up
//                 </Link>
//               </span>
//             </Typography> */}
//             </Box>
//             {/* <Divider>or</Divider> */}
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 2,
//                 justifyContent: "center",
//                 width: "100%",
//                 maxWidth: "400px", // Ensures buttons stay within a fixed width
//               }}
//             >
//               <Box
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: 2,
//                   justifyContent: "center",
//                   alignItems: "center", // Center vertically
//                   width: "100%",
//                   // marginBottom: "30px",
//                   maxWidth: "400px", // Ensures buttons stay within a fixed width
//                 }}
//               >
//                 {/* <Button
//                 type="submit"
//                 variant="standard"
//                 sx={{
//                   alignSelf: "center",
//                   width: "600px", // Increased width of the button
//                 }}
//               > */}

//                 {/* <GoogleLogin
//                 onSuccess={async (credentialResponse) => {
//                   const profileObj = jwtDecode(credentialResponse.credential); // Decoding JWT if needed
//                   handleSuccess(profileObj); // Handle user profile
//                 }}
//                 onError={() => {
//                   console.log("Login Failed");
//                 }}
//                 text="continue_with" // Change the text to "Continue with Google"
//               /> */}

//                 {/* </Button> */}

//                 <button
//                   type="submit"
//                   variant="standard"
//                   onClick={() => googleLogin()}
//                   onMouseEnter={(e) =>
//                     (e.currentTarget.style.backgroundColor = "#fbfcfe")
//                   } // Light gray on hover
//                   onMouseLeave={(e) =>
//                     (e.currentTarget.style.backgroundColor = "#ffffff")
//                   } // Reset to white
//                   size="small"
//                   style={{
//                     fontFamily: "avertastd, sans-serif",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "flex-start", // Align logo and text to center
//                     position: "relative", // Allows absolute positioning of the logo
//                     backgroundColor: "#ffffff",
//                     border: "1px solid #dcdcdc",
//                     borderRadius: "4px", // Smooth border radius
//                     padding: "0 ", // Remove left padding for logo
//                     color: "#0f203e",
//                     fontWeight: "430",
//                     fontSize: "16px",
//                     cursor: "pointer",
//                     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
//                     width: "350px",
//                     height: "42px",
//                     marginBottom: "10px",
//                     marginTop: "10px",
//                     textTransform: "none", // Prevent text from being capitalized
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       height: "100%",
//                       padding: "0 0px", // No padding around the logo
//                       marginRight: "0px", // Space between the logo and text
//                     }}
//                   >
//                     <img
//                       src={google}
//                       alt="Google Logo"
//                       className="google-logo"
//                       style={{
//                         height: "28px",
//                         marginLeft: "5px", // Push the logo closer to the button's left border
//                         pointerEvents: "none", // Prevent hover effect from applying to the image
//                       }}
//                     />
//                   </div>
//                   <span
//                     style={{
//                       marginLeft: "75px",
//                       color: "#0f203e",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Continue with Google
//                   </span>
//                 </button>

//                 <Button
//                   type="submit"
//                   // fullWidth
//                   // variant="standard"
//                   onClick={handleLogin}
//                   onMouseEnter={(e) =>
//                     (e.currentTarget.style.backgroundColor = "#fbfcfe")
//                   } // Light gray on hover
//                   onMouseLeave={(e) =>
//                     (e.currentTarget.style.backgroundColor = "#ffffff")
//                   } // Reset to white
//                   size="small"
//                   style={{
//                     fontFamily: "avertastd, sans-serif",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "flex-start", // Align logo and text to center
//                     position: "relative", // Allows absolute positioning of the logo
//                     backgroundColor: "#ffffff",
//                     border: "1px solid #dcdcdc",
//                     borderRadius: "4px", // Smooth border radius
//                     padding: "0 ", // Remove left padding for logo
//                     color: "#0f203e",
//                     fontWeight: "430",
//                     fontSize: "16px",
//                     cursor: "pointer",
//                     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
//                     width: "350px",
//                     height: "41px",
//                     marginBottom: "15px",
//                     textTransform: "none", // Prevent text from being capitalized
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       height: "100%",
//                       padding: "0 0px", // No padding around the logo
//                       marginRight: "0px", // Space between the logo and text
//                     }}
//                   >
//                     <img
//                       src={microsoft}
//                       alt="microsoft Logo"
//                       style={{
//                         height: "30px",
//                         marginLeft: "5px", // Push the logo closer to the button's left border
//                         pointerEvents: "none", // Prevent hover effect from applying to the image
//                       }}
//                     />
//                   </div>

//                   <span
//                     style={{
//                       marginLeft: "60px",
//                       color: "#0f203e",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Continue with Microsoft
//                   </span>
//                 </Button>
//               </Box>

//               <div>
//                 <br></br>
//               </div>
//             </Box>
//             {/* </Card> */}
//           </SignInContainer>
//         </ThemeProvider>
//       </div>
//     </>
//   );
// }

///// ////////////// OLD SIGN IN PAGE CODE

/*global chrome*/
import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import getSignInTheme from "./theme/getSignInTheme";

import { Link as Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import { useMsal } from "@azure/msal-react";

import LiznrLight from "../../Assets/LiznrLight.png";
import LiznrFav from "../../Assets/LiznrExtenIco.png";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  // margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.02) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.03) 0px 15px 35px -5px",
  backgroundColor: "#ffffff", // Default to white
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.2) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.04) 0px 15px 35px -5px",
    backgroundColor: "#ffffff", // Force white background in dark mode
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  padding: 20,
  backgroundImage:
    "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  backgroundRepeat: "no-repeat",
  ...theme.applyStyles("dark", {
    backgroundImage:
      "radial-gradient(at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
  }),
}));

export default function SignIn() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode } });
  const SignInTheme = createTheme(getSignInTheme(mode));
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [tokenClient, setTokenClient] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);

  const navigate = useNavigate();
  const backend = process.env.REACT_APP_BACKEND_HOST;
  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;

  // Privacy policy and terms and condition
  const privacy_policy = process.env.REACT_APP_PRIVACY_POLICY;
  const terms_and_conditions = process.env.REACT_APP_TERMS_OF_SERVICE;

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  // This is for google login
  // React.useEffect(() => {
  //   const initializeGoogleClient = () => {
  //     const client = window.google.accounts.oauth2.initTokenClient({
  //       client_id: '530303062762-2pnen77012a0tadp0ekpv61a9pdf0bce.apps.googleusercontent.com', // Replace with your client ID
  //       scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
  //       callback: handleAuthResponse,
  //     });
  //     setTokenClient(client);
  //   };

  //   if (window.google && window.google.accounts && window.google.accounts.oauth2) {
  //     initializeGoogleClient();
  //   }
  // }, []);

  // const handleAuthResponse = async (response) => {
  //   if (response.error) {
  //     console.error('Authentication failed:', response.error);
  //     return;
  //   }
  //   setIsSignedIn(true);
  //   console.log('Access token:', response.access_token);

  //   // Fetch user profile information
  //   try {
  //     const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
  //       headers: {
  //         Authorization: `Bearer ${response.access_token}`,
  //       },
  //     });
  //     const userInfoData = await userInfoResponse.json();
  //     setUserInfo(userInfoData); // Set user info in state
  //     console.log('User info:', userInfoData);
  //   } catch (error) {
  //     console.error('Error fetching user info:', error);
  //   }
  // };

  // const handleGoogleLogin = () => {
  //   if (tokenClient) {
  //     tokenClient.requestAccessToken();
  //     console.log("Token ",tokenClient);

  //   } else {
  //     console.error('Token client not initialized');
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const response = await axios.post(`${backend}/login/`, {
        username: data.get("email"),
        password: data.get("password"),
      });
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);
      console.log(response);

      navigate("/");
    } catch (error) {
      console.log("error while login", error);
      navigate("/sign-in");
    }
  };

  const validateInputs = () => {
    const email = document.getElementById("email");
    const password = document.getElementById("password");

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage("Password must be at least 6 characters long.");
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    return isValid;
  };

  async function sendLoggedInMessageToExtension(access, refresh) {
    try {
      const response = await chrome.runtime.sendMessage(
        "jboofaeiajhcbcgfpklmoooedclfggim",
        { type: "LOGIN", access_token: access, refresh_token: refresh }
      );
      console.log("Response from extension:", response);
    } catch (error) {
      console.error("Error sending message to extension:", error);
    }
  }

  async function checkLoginStatusFromExtension() {
    const extensionId = "jboofaeiajhcbcgfpklmoooedclfggim"; // Replace with your extension's ID

    try {
      const response = await chrome.runtime.sendMessage(extensionId, {
        type: "CHECK_LOGIN",
      });

      if (response && response.status === "success") {
        console.log("User is logged in on the extension");
      } else {
        console.log("User is not logged in on the extension");
      }
    } catch (error) {
      console.error("Failed to check login status from extension:", error);
    }
  }

  const handleSuccess = async (data) => {
    try {
      if (data) {
        localStorage.setItem("picture", data.picture);
      }
      await axios
        .post(`${backend}/auth/google/`, {
          email: data.email,
          name: data.name,
        })
        .then((res) => {
          const { access, refresh } = res.data;

          // Store tokens in localStorage or sessionStorage
          localStorage.setItem("access_token", access);
          localStorage.setItem("refresh_token", refresh);

          // notifyExtensionLogin(access);
          sendLoggedInMessageToExtension(access, refresh);

          navigate("/");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const { instance } = useMsal();
  // Microsoft
  const handleLogin = async () => {
    await instance
      .loginPopup(["openid", "profile", "User.Read"])
      .then((response) => {
        console.log(response.account.username);
        handleSuccess({
          email: response.account.username,
          name: response.account.name,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <ThemeProvider theme={showCustomTheme ? SignInTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card
          variant="outlined"
          style={{ padding: "5px", textAlign: "center" }}
        >
          <div style={{ marginTop: "5px auto" }}>
            <img
              src={LiznrLight}
              alt="logo"
              style={{ width: "100px", height: "auto", margin: "10px auto" }}
            />
          </div>
          {/* <div style={{ marginTop: "2rem" }}>
            <img
              src={LiznrFav}
              alt="logo"
              style={{ width: "100px", height: "auto", margin: "20px auto" }}
            />
          </div> */}

          <div
            className="text-center py-2"
            style={{ marginTop: "5rem", marginBottom: "2rem" }}
          >
            <Typography
              variant="string"
              className="text-black fw-medium"
              sx={{
                fontFamily: "Verdana, sans-serif",
                fontSize: "20px",
                marginBottom: "10px",
                paddingBottom: "10px",
              }}
            >
              Make meetings Effortless with Liznr
            </Typography>
            <br />
            <Typography
              variant="caption"
              className="text-black text-md fw-medium"
              sx={{ fontSize: "14px" }}
            >
              Your Smart{" "}
              <span className="register-link" style={{ color: "#DE00FF" }}>
                AI
              </span>{" "}
              Assistant
            </Typography>
          </div>

          {/* <Typography
            component="h1"
            variant="h4"
            sx={{ width: "100%", fontSize: "clamp(2rem, 10vw, 2.15rem)" }}
          >
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
            }}
          > */}
          {/* <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? "error" : "primary"}
                sx={{ ariaLabel: "email" }}
              />
            </FormControl>
            <FormControl> */}
          {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: "baseline" }}
                >
                  Forgot your password?
                </Link>
              </Box> */}
          {/* <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? "error" : "primary"}
              />
            </FormControl> */}
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ForgotPassword open={open} handleClose={handleClose} /> */}
          {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={validateInputs}
            >
              Sign in
            </Button> */}
          {/* <Typography sx={{ textAlign: "center" }}>
              Don&apos;t have an account?{" "}
              <span>
                <Link
                  href="/register"
                  variant="body2"
                  sx={{ alignSelf: "center" }}
                >
                  Sign up
                </Link>
              </span>
            </Typography> */}
          {/* </Box>
          <Divider>or</Divider>  */}
          {/* Changes made */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                // fullWidth
                variant="standard"
                sx={{ alignSelf: "center" }}
              >
                <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    const profileObj = jwtDecode(credentialResponse.credential); // Decoding JWT if needed

                    handleSuccess(profileObj); // Handle user profile
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  // shape='circle'
                />
              </Button>

              <Button
                type="submit"
                // fullWidth
                variant="standard"
                onClick={handleLogin}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#f2f2f2")
                } // Light gray on hover
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#ffffff")
                } // Reset to white
                startIcon={<i className="ci ci-microsoft ci-1x"></i>}
                size="small"
                sx={{
                  fontFamily: `"Google Sans", "arial","sans-serif"`,
                  border: "1px solid #dadce0",
                  width: "fit-content",
                  alignSelf: "center",
                  // borderRadius: "2.5px",
                  fontWeight: "500px",
                  fontSize: "14px",
                  height: "40px",
                  letterSpacing: "0.25px",
                  borderRadius: "4px",

                  // position: "relative",
                  // verticalAlign: "middle",
                  // whiteSpace: "nowrap",
                  backgroundColor: "#ffffff", // White background
                  color: "#000000", // Black text
                  "&:hover": {
                    backgroundColor: "#f2f2f2", // Slightly lighter background on hover
                  },
                }}
              >
                Sign in with Microsoft
              </Button>
            </Box>
          </Box>

          <div className="t&c" style={{ marginTop: "4.6rem" }}>
            <p className="text-sm fw-small" style={{ color: "grey" }}>
              By using Liznr you agree to the
              <br />
              <Route
                to={terms_and_conditions}
                className="register-link"
                target="_blank"
                style={{ fontSize: "12px", color: "#DE00FF" }}
              >
                {" "}
                Terms of Service
              </Route>{" "}
              and{" "}
              <Route
                to={privacy_policy}
                className="register-link"
                target="_blank"
                style={{ fontSize: "12px", color: "#DE00FF" }}
              >
                {" "}
                Privacy Policy
              </Route>
            </p>
          </div>
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}
