import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authorizationReducer from './Slices/AuthorizationSlice';
import userInfoReducer from './Slices/UserInfoSlice';
import workspaceReducer from './Slices/WorkspaceSlice'; // Ensure correct naming

// Combine all reducers
const rootReducer = combineReducers({
  authorization: authorizationReducer,
  userInfo: userInfoReducer,
  workspace: workspaceReducer, // Ensure naming consistency
});

// Configure the store without persistence
const store = configureStore({
  reducer: rootReducer,
});

// Export the store
export default store;
