import React from 'react'
import DenseTable from '../Worksapces/WorkspaceTable'
import { useSelector } from 'react-redux'
import DeleteAlert from '../Utilities/Alert'
const ManageAccounts = () => {
    const worksapceInfo = useSelector(state => state.workspace.workspaceDetails)
    console.log(worksapceInfo);
    const data = JSON.parse(sessionStorage.getItem('organization_id'))
    
  return (
    <div><DenseTable data={data} />
    
    </div>
  )
}

export default ManageAccounts