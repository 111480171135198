import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Teams from './Teams';
import People from './People';
import { Divider, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

export default function ManageWorkspace() {
  const [value, setValue] = React.useState('1');
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === '1'){
      navigate('teams')
    }
    if(newValue === '2'){
      navigate('people')
    }
  };
  React.useEffect(() => {
   
   
    
    if(window.location.pathname === '/organization'){
      navigate('teams')
      
    }
    else if(window.location.pathname==='/organization/teams'){
      setValue('1')
    }
    else if(window.location.pathname==='/organization/people'){
      setValue('2')
    }
    
  }, [window.location.pathname])
  

  const navigate = useNavigate()

  return (
    <Box sx={{ width: '100%', typography: 'body1',padding:'10px' }}>
        <Typography variant="h6" component="h2" gutterBottom>
        Manage Organization
      </Typography>
      <Divider/>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Teams" value="1" />
            <Tab label="People" value="2" />
            {/* <Tab label="Item Three" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1"></TabPanel>
        <TabPanel value="2"></TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
      <Outlet/>
    </Box>
  );
}
