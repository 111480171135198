import { React, useRef, useState, useMemo, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { AppProvider } from "@toolpad/core/AppProvider";
import {
  PageContainer,
  PageContainerToolbar,
} from "@toolpad/core/PageContainer";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// API
import apiClient from "../AxiosInterceptors/Interceptor";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setWorkspace,
  setWorkspaceInfo,
} from "../../Store/Slices/WorkspaceSlice";

const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
const backend = process.env.REACT_APP_BACKEND_HOST;
const NAVIGATION = [
  {
    segment: "",
    title: "Teach Liznr",
    icon: <DashboardIcon />,
  },
];

function useDemoRouter(initialPath) {
  const [pathname, setPathname] = useState(initialPath);

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);

  return router;
}

function PageToolbar({ onUploadSuccess }) {
  const [open, setOpen] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const worksapceInfo = useSelector(
    (state) => state.workspace.workspaceDetails
  );
  const workspaceIds = worksapceInfo.length > 0 ? worksapceInfo[0].id : null;
  const [workspaceId, setWorkspaceId] = useState(workspaceIds);
  const access_token = localStorage.getItem("access_token");
  const [teams, setTeams] = useState([]);

  const [formData, setFormData] = useState({
    title: "",
    domain: "",
    team: "",
  });

  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const [upload, setUpload] = useState(false);
  const dispatch = useDispatch();

  const fetchTeams = async () => {
    // setLoading(true)
    await axios
      .get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.data);
          // setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false)
      });
  };

  const workspace = async () => {
    try {
      await axios
        .get(
          `${dashboard_backend}/team/workspaces/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.status === 200) {
            // return res.data
            setWorkspaceId(res.data[0].id);
            dispatch(setWorkspace(true));
            dispatch(setWorkspaceInfo(res.data));
            if (workspaceId !== null) {
              fetchTeams();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (worksapceInfo.length > 0) {
      fetchTeams();
    } else {
      workspace();
    }
  }, [workspaceId]);

  // Function to open the dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setUploadProgress(0); // Reset progress on close
  };

  // Handle input change for the title
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) {
      alert("Please select a file");
      return;
    }

    // Create FormData and append the file and title
    const data = new FormData();
    data.append("title", formData.title); // Add the title to the FormData
    data.append("file", file); // Add the file to the FormData
    // data.append('user', 1); // Add the user field if needed
    data.append("team_name", formData.team);

    const access_token = localStorage.getItem("access_token");
    setUpload(false);
    await axios
      .post(`${dashboard_backend}/documents/`, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percent = Math.round((current / total) * 100);
          setUploadProgress(percent); // Update progress
        },
      })
      .then((response) => {
        handleClose(); // Close the dialog after successful upload
        // window.location.reload()
        onUploadSuccess();

        // navigate('/teach')
      })
      .catch((error) => {
        console.error("Error uploading file and details:", error);
        setUpload(false);
      });
  };

  const options = [
    { label: "Technology", value: "tech" },
    { label: "Hospital", value: "hospital" },
    { label: "Auto Mobiles", value: "automobile" },
    { label: "Telecom", value: "telecom" },
  ];

  return (
    <PageContainerToolbar>
      <Stack direction="row" spacing={1} alignItems="center">
        <Button
          variant="outlined"
          size="small"
          color="neutral"
          startIcon={<UploadIcon fontSize="inherit" />}
          onClick={handleClickOpen}
        >
          Upload
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Upload File</DialogTitle>
          <DialogContent>
            {/* <TextField
                            autoFocus
                            margin="dense"
                            name="title"
                            label="Title"
                            type="text"
                            fullWidth
                            value={formData.title}
                            onChange={handleInputChange}
                        /> */}
            <FormControl fullWidth sx={{ marginTop: "20px" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  backgroundColor: "white", // Adds background to prevent overlap
                  px: 0.5, // Padding around label text
                  transform: "translate(14px, 14px) scale(1)", // Default position
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(14px, -4px) scale(0.85)", // Adjust position when focused
                    backgroundColor: "white", // Keep the background when it shrinks
                    padding: "0 4px", // Add spacing around the text
                  },
                }}
              >
                Select Team
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={formData.team}
                label="Domain"
                onChange={handleInputChange}
                sx={{
                  color: "black",
                  "& .MuiSelect-select": {
                    padding: "16.5px 14px", // Ensures proper alignment of the text
                  },
                  "& fieldset": {
                    borderColor: "#ccc", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#888", // Hover state border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#1976d2", // Focused border color (default MUI primary color)
                  },
                }}
                name="team"
              >
                {teams &&
                  teams.map((data, i) => (
                    <MenuItem key={i} value={data.team_name}>
                      {data.team_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <input
              type="file"
              ref={fileInputRef}
              style={{ marginTop: "16px" }}
            />
            {uploadProgress > 0 && (
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ marginTop: "16px" }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </PageContainerToolbar>
  );
}

export default function Teach(props) {
  const { window } = props;
  const router = useDemoRouter("/orders");
  const theme = useTheme();
  const [documents, setDocuments] = useState([]);
  const demoWindow = window ? window() : undefined;
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const getDocuments = async () => {
    const access_token = localStorage.getItem("access_token");
    await axios
      .get(`${dashboard_backend}/documents/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        setDocuments(response.data);
        // const data = response.data;
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });

    // const response = await apiClient.get('/documents/')
    // console.log(response);
  };

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      getDocuments();
      setShouldRefresh(false); // Reset after refresh
    }
  }, [shouldRefresh]);

  const controlDelete = async (document_id) => {
    try {
      const response = await axios.delete(
        `${dashboard_backend}/documents/${document_id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response.status === 204) {
        getDocuments();
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return new Intl.DateTimeFormat("en-US", {
      dateStyle: "medium", // "full", "long", "medium", "short"
      timeStyle: "short", // Optional: Add if you want to include time
    }).format(date);
  };

  return (
    <Paper sx={{ p: 2, width: "100%" }}>
      <PageContainer
        slots={{
          toolbar: () => (
            <PageToolbar onUploadSuccess={() => setShouldRefresh(true)} />
          ),
        }}
        title="Train Liznr"
        breadcrumbs={[{ label: "" }]}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Team</TableCell>
                {/* <TableCell>Domain</TableCell> */}
                <TableCell align="left">Date</TableCell>
                <TableCell>File</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((data, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{data.team_name}</TableCell>
                  {/* <TableCell>{'-'}</TableCell> */}
                  <TableCell>
                    {data.uploaded_at && formatDate(data.uploaded_at)}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      const parts = data.file.split("/");
                      return parts[parts.length - 1];
                    })()}
                  </TableCell>
                  <TableCell
                    sx={{ cursor: "pointer" }}
                    onClick={() => controlDelete(data.id)}
                  >
                    {<DeleteOutlineIcon />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContainer>
    </Paper>
  );
}