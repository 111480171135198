import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';

export default function DeleteAlert({children,deleting,name,controlDelete}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {

    setOpen(true);
  };

  const handleClose = (e) => {
    if(e.target.value === "Agree"){
      controlDelete();
    }

    
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" sx={{padding:0,color:"black"}}  onClick={handleClickOpen}>
        {children}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete ${deleting} ${name} ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted, you will not be able to recover this {deleting}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} value="Disagree">Cancle</Button>
          <Button onClick={handleClose} value="Agree" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
