import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const CreateTeam = ({ formData, setFormData }) => {
  return (
    <Box
      sx={{
        paddingY: 2,
        paddingX: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        textAlign: 'start',
        gap: 2,
        marginTop: 8,
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Create Teams in your Organization
      </Typography>
      <Typography variant="body1" gutterBottom>
        Teams allow your organization to customize settings for different groups of people.
      </Typography>
      <Box component="form" sx={{ width: '100%', mt: 2 }}>
        <Typography variant="body1" gutterBottom>
          Name your new Team
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          name="teamName"
          placeholder="Enter Team name"
          value={formData.teamName}
          onChange={(e) =>
            setFormData({
              ...formData,
              teamName: e.target.value,
              forward: !!e.target.value,
            })
          }
        />
      </Box>
    </Box>
  );
};

export default CreateTeam;
