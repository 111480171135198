// /////////////////////  New Managed acoount Page
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   TextField,
//   Typography,
//   Button,
//   Avatar,
//   Grid,
//   Divider,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import "./ProfileAndAccount.css";

// export default function ProfileAndAccount() {
//   // const [rows, setRows] = useState([]); // Teams data for table
//   const [organizationName, setOrganizationName] = useState(""); // Organization name
//   const [teams, setTeams] = useState([]); // State for teams data
//   const [name, setName] = useState(localStorage.getItem("name") || ""); // Fetch from localStorage
//   const [jobTitle, setJobTitle] = useState(
//     localStorage.getItem("jobTitle") || ""
//   ); // Fetch from localStorage

//   const [department, setDepartment] = useState(
//     localStorage.getItem("department") || ""
//   ); // Fetch from localStorage
//   const [userInitial, setUserInitial] = useState(""); // Initial/logo synced with top-right avatar

//   const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
//   const access_token = localStorage.getItem("access_token");
//   const workspaceData = JSON.parse(sessionStorage.getItem("organization_id"));
//   const workspaceId =
//     workspaceData && workspaceData.length > 0 && workspaceData[0].id;

//   // const navigate = useNavigate();

//   // Function to fetch the avatar data for top-right corner and sync
//   const fetchAvatarData = async () => {
//     try {
//       const response = await axios.get(`${dashboard_backend}/user/avatar`, {
//         headers: { Authorization: `Bearer ${access_token}` },
//       });

//       if (response.status === 200 && response.data) {
//         const { initial, logo } = response.data;
//         // Fallback to the first letter of the organization or user's name
//         const dynamicFallback =
//           organizationName?.charAt(0).toUpperCase() || "U"; // Default to 'U' if no organization name
//         setUserInitial(initial || logo || dynamicFallback);
//       }
//     } catch (error) {
//       console.error("Error fetching avatar data:", error);
//       // Handle error by setting a dynamic fallback based on organization or user name
//       const dynamicFallback = organizationName?.charAt(0).toUpperCase() || "U"; // Default to 'U' if no organization name
//       setUserInitial(dynamicFallback);
//     }
//   };

//   // Fetch organization and team details
//   const fetchOrganizationDetails = async () => {
//     try {
//       // Fetch organization name
//       const orgResponse = await axios.get(
//         `${dashboard_backend}/team/workspaces/${workspaceId}/`,
//         {
//           headers: { Authorization: `Bearer ${access_token}` },
//         }
//       );

//       if (orgResponse.status === 200) {
//         setOrganizationName(orgResponse.data.name);
//         console.log(`Getting data from organization ${orgResponse.data}`);
//       }

//       // Fetch teams in the organization
//       const teamsResponse = await axios.get(
//         `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
//         {
//           headers: { Authorization: `Bearer ${access_token}` },
//         }
//       );

//       if (teamsResponse.status === 200) {
//         setTeams(teamsResponse.data);
//         console.log(`getting data from Teams ${teamsResponse.data}`);
//       }
//     } catch (error) {
//       console.error("Error fetching organization or teams data:", error);
//     }
//   };

//   useEffect(() => {
//     if (workspaceId) {
//       fetchOrganizationDetails();
//     }
//   }, [workspaceId]);

//   // Fetch user data and set the avatar and name dynamically
//   const fetchUserData = async () => {
//     try {
//       const response = await axios.get(`${dashboard_backend}/user/profile`, {
//         headers: { Authorization: `Bearer ${access_token}` },
//       });

//       if (response.status === 200 && response.data) {
//         const { username, name } = response.data;

//         const initial = username?.charAt(0).toUpperCase() || "U"; // Get first letter of username
//         setUserInitial(initial);

//         // Only set the name from the API if it's not already set in localStorage
//         if (!localStorage.getItem("name") && name) {
//           setName(name);
//           localStorage.setItem("name", name); // Save to localStorage
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//       setUserInitial(""); // Fallback to default
//       setName("User");
//     }
//   };

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   // Save the name, job title, and department to localStorage
//   useEffect(() => {
//     if (name) {
//       localStorage.setItem("name", name);
//     }
//     if (jobTitle) {
//       localStorage.setItem("jobTitle", jobTitle);
//     }
//     if (department) {
//       localStorage.setItem("department", department);
//     }
//   }, [name, jobTitle, department]);

//   // const handleSSOToggle = () => {
//   //   setSSOEnabled((prev) => !prev);
//   // };
//   // Dynamically update the avatar initial based on userName

//   return (
//     <Box className="profile-container">
//       {/* Profile & Account Section */}
//       <Typography className="profile-header">Profile & Account</Typography>
//       <Divider sx={{ marginY: 2 }} />
//       <Box className="avatar-upload">
//         {/* Dynamically show the initial/logo */}
//         <Avatar sx={{ width: 56, height: 56, bgcolor: "#2196f3" }}>
//           {userInitial}
//         </Avatar>
//         <Button variant="outlined" component="label" sx={{ marginLeft: 2 }}>
//           Upload Photo
//           <input hidden accept="image/*" type="file" />
//         </Button>
//       </Box>
//       <Grid container spacing={2} marginTop={2}>
//         <Grid item xs={12}>
//           <TextField
//             fullWidth
//             label="DisplayName"
//             variant="outlined"
//             value={name} // Set fetched name here
//             onChange={(e) => setName(e.target.value)} // Update state dynamically
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             fullWidth
//             label="Job Title"
//             variant="outlined"
//             value={jobTitle} // Save job title in state and localStorage
//             onChange={(e) => setJobTitle(e.target.value)} // Update state and localStorage dynamically
//             placeholder="Not provided"
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField
//             fullWidth
//             label="Department"
//             variant="outlined"
//             value={department} // Save department in state and localStorage
//             onChange={(e) => setDepartment(e.target.value)} // Update state and localStorage dynamically
//             placeholder="Not provided"
//           />
//         </Grid>
//       </Grid>
//       <Box sx={{ marginTop: 3 }}>
//         <Typography variant="body2" color="textSecondary">
//           Your primary email cannot be changed because it is connected to an SSO
//           account.
//         </Typography>
//         <TextField
//           fullWidth
//           label="Primary Email"
//           variant="outlined"
//           defaultValue="atoshsingh7536@gmail.com"
//           margin="normal"
//           disabled
//         />
//         {/* <Button className="gradient-button">Add Password</Button> */}
//       </Box>
//       <Box className="sso-section">
//         <Typography variant="h6">Single Sign-On (SSO)</Typography>
//         <Typography variant="body2" color="textSecondary" gutterBottom>
//           Authorizing single sign-on allows you to sign in using credentials
//           associated with the linked account.
//         </Typography>
//         <Box display="flex" flexDirection="column" alignItems="flex-end">
//           <Typography>Sign-in with Google is enabled</Typography>
//           {/* <Button variant="outlined" sx={{ marginTop: 1 }}>
//             Add SSO
//           </Button> */}
//         </Box>

//         <button>Update</button>
//       </Box>
//       <Divider sx={{ marginY: 4 }} />
//       {/* Table Section */}
//       <Typography variant="h4" gutterBottom>
//         Organizations
//       </Typography>
//       <Box className="profile-container">
//         {/* Organization Name */}
//         <Typography variant="h5" sx={{ marginBottom: 2 }}>
//           {organizationName || "Loading..."}
//         </Typography>

//         {/* Teams Table */}
//         <Typography variant="h6" gutterBottom>
//           Teams in Organization
//         </Typography>
//         <TableContainer component={Paper} className="table-container">
//           <Table sx={{ minWidth: 650 }} size="small" aria-label="Teams Table">
//             <TableHead>
//               <TableRow>
//                 <TableCell className="table-header" align="left">
//                   S.No
//                 </TableCell>
//                 <TableCell className="table-header" align="left">
//                   Team Name
//                 </TableCell>
//                 <TableCell className="table-header" align="left">
//                   Members Count
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {teams.length > 0 ? (
//                 teams.map((team, i) => (
//                   <TableRow key={team.id}>
//                     <TableCell align="left">{i + 1}</TableCell>
//                     <TableCell align="left">{team.team_name}</TableCell>
//                     <TableCell align="left">{team.members_count}</TableCell>
//                   </TableRow>
//                 ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={3} align="center">
//                     No Teams Found
//                   </TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// }

/////////////////////////////////////////////////////

/////////////////////  New Managed Account Page
/////////////////////  New Managed Account Page
//

//////////////////////////////////////////  Code with Updtae Button

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Typography,
  Button,
  Avatar,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function ProfileAndAccount() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [organizationName, setOrganizationName] = useState("");
  const [teams, setTeams] = useState([]);
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [jobTitle, setJobTitle] = useState(
    localStorage.getItem("jobTitle") || ""
  );
  const [department, setDepartment] = useState(
    localStorage.getItem("department") || ""
  );
  const [userInitial, setUserInitial] = useState(" ");
  const [isUpdating, setIsUpdating] = useState(false); // Loading state for update

  const dashboard_backend = process.env.REACT_APP_DASHBOARD_BACKEND_HOST;
  const access_token = localStorage.getItem("access_token");
  const workspaceData = JSON.parse(sessionStorage.getItem("organization_id"));
  const workspaceId =
    workspaceData && workspaceData.length > 0 && workspaceData[0].id;

  const fetchAvatarData = async () => {
    try {
      const response = await axios.get(`${dashboard_backend}/user/avatar`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (response.status === 200 && response.data) {
        const { initial, logo } = response.data;
        const dynamicFallback =
          organizationName?.charAt(0).toUpperCase() || "U";
        setUserInitial(initial || logo || dynamicFallback);
      }
    } catch (error) {
      console.error("Error fetching avatar data:", error);
      const dynamicFallback = organizationName?.charAt(0).toUpperCase() || "U";
      setUserInitial(dynamicFallback);
    }
  };

  const fetchOrganizationDetails = async () => {
    try {
      const orgResponse = await axios.get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (orgResponse.status === 200) {
        setOrganizationName(orgResponse.data.name);
      }
      const teamsResponse = await axios.get(
        `${dashboard_backend}/team/workspaces/${workspaceId}/team/`,
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );
      if (teamsResponse.status === 200) {
        setTeams(teamsResponse.data);
      }
    } catch (error) {
      console.error("Error fetching organization or teams data:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${dashboard_backend}/user/profile`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      if (response.status === 200 && response.data) {
        const { username, name } = response.data;
        const initial = username?.charAt(0).toUpperCase() || "U";
        setUserInitial(initial);
        if (!localStorage.getItem("name") && name) {
          setName(name);
          localStorage.setItem("name", name);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserInitial("");
      setName("User");
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchOrganizationDetails();
    }
  }, [workspaceId]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      localStorage.setItem("name", name);
      localStorage.setItem("jobTitle", jobTitle);
      localStorage.setItem("department", department);

      // Assuming an API exists to update the profile details
      const response = await axios.put(
        `${dashboard_backend}/user/profile`,
        { name, jobTitle, department },
        {
          headers: { Authorization: `Bearer ${access_token}` },
        }
      );

      if (response.status === 200) {
        alert("Profile updated successfully!");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box className="profile-container" padding={isMobile ? 2 : 5}>
      <Typography className="profile-header" variant="h6" textAlign="center">
        Profile & Account
      </Typography>
      <Divider sx={{ marginY: 2 }} />
      <Box display="flex" alignItems="center" justifyContent="center">
        <Avatar
          sx={{ width: 56, height: 56, bgcolor: "#DE00FF" }}
          src="../../Assets/Profile_image.png" // Replace with your static image URL
          alt="Profile Image"
        >
          {userInitial}
        </Avatar>
        <Button variant="outlined" component="label" sx={{ marginLeft: 2 }}>
          Upload Photo
          <input hidden accept="image/*" type="file" />
        </Button>
      </Box>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Display Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Job Title"
            variant="outlined"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            placeholder="Not provided"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Department"
            variant="outlined"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            placeholder="Not provided"
          />
        </Grid> */}
      </Grid>
      <Box marginTop={3}>
        <Typography variant="body2" color="textSecondary">
          Your primary email cannot be changed because it is connected to an SSO
          account.
        </Typography>
        <TextField
          fullWidth
          label="Primary Email"
          variant="outlined"
          defaultValue="atoshsingh7536@gmail.com"
          margin="normal"
          disabled
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          display: "block", // Centering the button
          margin: "20px auto", // Adds vertical spacing and centers horizontally
          width: "60%", // Increases the button's width
          maxWidth: "300px", // Ensures it doesn't become too wide
          padding: "12px", // Makes the button larger
          background: "linear-gradient(90deg, #8000FF, #DE00FF)", // Gradient color
          color: "#fff",
          fontWeight: "bold",
          textTransform: "uppercase",
          borderRadius: "25px", // Makes it pill-shaped
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds a shadow
          "&:hover": {
            background: "linear-gradient(90deg, #DE00FF, #8000FF)", // Reverse gradient on hover
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", // Enhanced hover effect
          },
          "&:disabled": {
            background: "linear-gradient(90deg, #8000FF, #DE00FF)", // Keeps the same gradient for disabled
            opacity: 0.7,
          },
        }}
        onClick={handleUpdate}
        disabled={isUpdating}
      >
        {isUpdating ? "Updating..." : "Update"}
      </Button>
      <Divider sx={{ marginY: 4 }} />
      <Typography variant="h5" gutterBottom>
        Organizations
      </Typography>
      <Typography variant="h6">{organizationName || "Loading..."}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell>Members Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.length > 0 ? (
              teams.map((team, i) => (
                <TableRow key={team.id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{team.team_name}</TableCell>
                  <TableCell>{team.members_count}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No Teams Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
